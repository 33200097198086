/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';
import Loader from 'react-loader-spinner';
import moment from "moment-timezone";
import { getVoicemails, fetchAllOfficeVoicemails } from '../actions/FetchVoicemails'
import Audio from './Audio'
import _ from "lodash";

const VoicemailTable = (props) => {
  const { classes, params, offices, officeDoc } = props;
  const [ voicemails, setVoicemails ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    const getOneOffice = async () => {
      if(officeDoc[params.office][0].five9.skills.answering){
        const returnedVoicemails = await getVoicemails(officeDoc[params.office][0].officeInformation.name, officeDoc[params.office][0].five9.skills.answering, setVoicemails);
        setVoicemails(returnedVoicemails.sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.date > b.date) ? 1 : -1) : -1));
        setLoading(false);
      }
      else{
        setVoicemails([]);
        setLoading(false);
      }
    }
    const getAllOffices = async () => {
      let returnedVoicemails = await fetchAllOfficeVoicemails(offices, officeDoc);
      setVoicemails(_.flatten(returnedVoicemails).sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.date > b.date) ? 1 : -1) : -1));
      setLoading(false);
    }
    if(officeDoc){
      if(params.office === 'all-offices'){
        getAllOffices();
      }
      else{
        getOneOffice();
      }
    }
  }, [params.office, offices, officeDoc])
  return (
    <Table aria-label="sticky table" stickyHeader>
        <TableHead>
          <TableRow classes={{root: classes.tableHeader}}>
            <TableCell align='left'>
              Office
            </TableCell>
            <Hidden mdDown>
              <TableCell align='left'>
                Date
              </TableCell>
            </Hidden>
            <TableCell align='left'>
              Phone Number
            </TableCell>
            <TableCell align='center'>
              Recording
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {voicemails[0] ? (voicemails.map(row => (
            row ? 
              <TableRow  key={row.file}>
                <TableCell align="left" component="th" scope="row">
                  {row.name}
                </TableCell>
                <Hidden mdDown>
                  <TableCell align="left">
                    {moment.unix(row.date).format("MMMM Do, YYYY")}
                  </TableCell>
                </Hidden>
                <TableCell align="left">
                  {row.number}
                </TableCell>
                <TableCell align="center">
                  <Audio file={row.file} setVoicemails={setVoicemails} voicemails={voicemails}/>
                </TableCell>
              </TableRow>
              : null
          ))) :
          loading ?
          <TableRow key="loading">
              <TableCell align='center' scope='row' colSpan={4}>
                <Loader
                  type="Oval"
                  color="black"
                  height="20"
                  width="20"
                />
              </TableCell>
          </TableRow>
          : 
          <TableRow key="loading">
              <TableCell align='center' scope='row' colSpan={4}>
                There are no current voicemails
              </TableCell>
          </TableRow>
        }
        </TableBody>
      </Table>
  );
}

const styles = theme => ({
});
  const mapStateToProps = (state) => {
    return {
        officeDoc: state.firestore.ordered
    };
  }  
  export default compose(  
    connect(mapStateToProps), 
    withStyles(styles, { withTheme: true })
  )(VoicemailTable);