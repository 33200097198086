import React, {useState, useEffect}  from 'react';
import Play from '@material-ui/icons/PlayArrowRounded'
import Button from '@material-ui/core/Button';
import Pause from '@material-ui/icons/Pause'
import FastForward from '@material-ui/icons/FastForward';
import FastRewind from '@material-ui/icons/FastRewind';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Dialog } from "@material-ui/core";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { setlistened, getlistened, deleteVoicemail } from '../actions/FetchVoicemails'



const Audio = ({file, voicemails, setVoicemails}) => {
    const [recordingPlaying , setrecordingPlaying] = useState(false);
    const [open, setOpened] = useState(false);
    const [audioColumn, setAudioColumn] = useState({
        backgroundColor: '#ec423d',
        height: '1px',
        marginBottom: '-3%',
        overflow: 'hidden',
        width: '0%'
      });
    
    useEffect(()=>{
        const fetchData = async () =>{
            const listened = await getlistened(file);
            if(listened){
                setAudioColumn({
                    backgroundColor: '#ec423d',
                    height: '1px',
                    marginBottom: '-3%',
                    overflow: 'hidden',
                    width: '100%'
                });
            }
        }
        fetchData();
    }, [file]);
    
    
    const recordingPlayPause = (id) =>{
        var audioTags = document.getElementsByTagName("audio");
        for (var recording of audioTags) { 
            if(!recording.paused && recording.id !== id){
                recording.pause();
            }
        } 
        if(recordingPlaying){
            setrecordingPlaying(false);
            document.getElementById(id).pause();
        }
        else{
            setrecordingPlaying(true);
            document.getElementById(id).play();
            document.getElementById(id).ontimeupdate = function() {updateProgressOfRecording(document.getElementById(id))};
            document.getElementById(id).onended = () => {setlistened(id)};
        }
    }

    const rewind = (id) => {
        document.getElementById(id).currentTime -= 5;
    };
    
    const fastForward = (id) => {
    document.getElementById(id).currentTime += 5;
    };

    const renderFastForwardRewindButtin = (direction, file) => {
        return (
          <Tooltip title={direction === 'rewind' ? 'rewind (5s)' : 'fastforward (5s)'}>
            <IconButton
              color='inherit'
              disabled={!recordingPlaying}
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                direction === 'rewind' ? rewind(file) : fastForward(file);
              }}
              aria-label={direction === 'rewind' ? 'rewind (5s)' : 'fastforward (5s)'}
            >
              {direction === 'rewind' ? <FastRewind fontSize='small'/> : <FastForward fontSize='small'/>}
            </IconButton>
          </Tooltip>
        )
    };

    const updateProgressOfRecording = (audioElement) => {
        setAudioColumn({
            backgroundColor: '#ec423d',
            height: '1px',
            marginBottom: '-3%',
            overflow: 'hidden',
            width: `${audioElement.currentTime / audioElement.duration * 100}%`
          });
        if(audioElement.paused){
            setrecordingPlaying(false);
        }
    }

    const manageDeleting = (id) => {
        setOpened(true);
    }


    const renderDeleteButton = (id) =>{
        return (
            <>
                <IconButton
                color='inherit'
                disabled={recordingPlaying}
                onClick={() => {
                    manageDeleting(id);
                }}
                aria-label='delete voicemail'
                >
                    <DeleteIcon />
                </IconButton>
            </>
          );
    }

    const renderDialog = () => {
        const removeVoicemail = () => {
            deleteVoicemail(file);
            // eslint-disable-next-line
            const newVoicemails = voicemails.filter((voicemail) => {
                if(voicemail){
                    if(file !== voicemail.file){
                        return voicemail;
                    }
                }
            })
            setVoicemails(newVoicemails);
            handleClose();
        }

        const handleClose = () => {
            setOpened(false);
        }

        return (
            <Dialog fullWidth={true} open={open} onClose={() => {renderDeleteButton(file)}} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you would like to delete this voicemail?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {handleClose()}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={removeVoicemail} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        )   
    }

    return(
        <span>
            {recordingPlaying  ? renderFastForwardRewindButtin('rewind', file) : null}
            <IconButton  color="inherit" onClick={() => {recordingPlayPause(file)}}>
                {recordingPlaying ?  <Pause fontSize='inherit'/> : <Play fontSize='inherit'/> }
                <audio id={file} hidden onEnded={() => {setrecordingPlaying(false)}}>
                    <source src={file} type="audio/mp3"/>
                </audio>
            </IconButton>
            {recordingPlaying  ? renderFastForwardRewindButtin('forward', file) : renderDeleteButton(file)}
            <div style={audioColumn} />
            {renderDialog()}
        </span>
    )
}
  
export default Audio;