import React from "react";
import Loader from "./Loader";
import CallLogDetails from "./CallLogDetails";

const CallLogsDetails = ({
  setRefresh,
  classes,
  items,
  offeringsCategories,
  allOffices,
  params,
  isLoading,
  user,
  updateCompletedTotal,
  showEntriesFor,
  entryCollection
}) => {

  if (isLoading) {
    return <Loader />;
  }

  return items.map((singleItem, index) => {
    return (
      <CallLogDetails
        setRefresh={setRefresh}
        key={index}
        singleItem={singleItem}
        params={params}
        classes={classes}
        allOffices={allOffices}
        offeringsCategories={offeringsCategories}
        user={user}
        updateCompletedTotal={updateCompletedTotal}
        showEntriesFor={showEntriesFor}
        collection={entryCollection}
      />
    );
  });
};

export default CallLogsDetails;
