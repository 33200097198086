import React, { Component } from 'react';
import { connect } from "react-redux";
import { LastLocationProvider } from 'react-router-last-location';
import { 
	fetchUser, offeringsCategoriesInitialize
} from '../actions';
import { Switch, Route } from 'react-router-dom';
import WebFont from 'webfontloader';
import Home from './Home';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

import './styles.css';

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700,900', 'sans-serif']
  }
});

class App extends Component {
  UNSAFE_componentWillMount() {
    this.props.fetchUser();
    this.props.offeringsCategoriesInitialize();
  }

  render() {
    return (
      <div className="App">
        <LastLocationProvider>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route path='/reset-password/:oobCode/:redirectUri/:emailParam' component={ResetPassword} />
            <Route path='/reset-password/:oobCode/:emailParam' component={ResetPassword} />
            <Route path='/:office/:reportDateStart/:reportDateEnd' component={Home} />
            <Route path='/:office/:reportDate' component={Home} />
            <Route path='/:office' component={Home} />
            <Route path='/' component={Home} />
          </Switch>
        </LastLocationProvider>
      </div>
    );
  }
}


export default connect(null, { fetchUser, offeringsCategoriesInitialize })(App);
