import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import MaskedInput from "react-text-mask";
import { TextField, Button, Typography, Divider, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { fetchUsrInformation, updateAccountInformation } from "./ManageUsers";
import Loader from "react-loader-spinner";

const UserPage = props => {
  const { classes, uid } = props;
  const [userInfo, setUserInfo] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const newData = await fetchUsrInformation(uid);
      setUserInfo({
        firstName: newData.firstName,
        lastName: newData.lastName,
        email: newData.email,
        phone: newData.phone ? newData.phone : ''
      });
    };
    fetchData();
  }, [uid]);

  const onSubmit = async (values) => {
    setSaving(true);
    await updateAccountInformation({
      uid,
      userInformation: { 
        'firstName': values.firstName,
        'lastName': values.lastName, 
        'userEmail': values.email, 
        'userPhone': values.phone 
      }
    });
    setSaving(false);
  };

  function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          "(",
          /[1-9]/,
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ]}
        placeholderChar={"\u2000"}
        showMask
      />
    );
  }

  const nameValidate = value => {
    let errorMessage;
    if (/\d/i.test(value) || !/\D/i.test(value)) {
      errorMessage = "Invalid name";
    }
    return errorMessage;
  };

  const emailValidate = value => {
    let errorMessage;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      errorMessage = 'Invalid email address';
    }
    return errorMessage;
  };

  const textField = ({ field, form, ...props }) => {
    const { errors, touched } = form;
    return (
      <TextField
        {...field}
        {...props}
        variant="outlined"
        className={classes.textField}
        error={!!(
          touched[field.name] && 
          errors[field.name]
        )}
      />
    );
  };

  const emailField = ({ field, form, ...props }) => {
    const { errors, touched } = form;
    return (
      <TextField
        {...field}
        {...props}
        variant="outlined"
        className={classes.textField}
        error={!!(
          touched[field.name] && 
          errors[field.name]
        )}
      />
    );
  };

  const phoneField = ({field, form, ...props}) => {
    const { errors, touched } = form;
    return  <TextField 
        name="phone" 
        InputProps={{ inputComponent: TextMaskCustom }}
        {...field} 
        {...props}
        variant='outlined' 
        className={classes.textField}
        error={!!(
          touched[field.name] && 
          errors[field.name]
        )}
    />
  };

  const phoneValidate = value => {
    let errorMessage;
    value = value.replace(/\D/g, '')
    if (value.length < 10) {
      errorMessage = 'Invalid Phone Number';
    }
    return errorMessage;
  };

  return (
    <Paper className={classes.settings}>
        <div className={classes.centeredTitle}>
          <Typography variant="h6" style={{ marginBottom: "10px" }}>
            {userInfo ? userInfo.firstName + " " + userInfo.lastName : null}
          </Typography>
          <Divider />
        </div>
        {userInfo ? (
          <div className={classes.userForm}>
            <Formik
              initialValues={userInfo}
              onSubmit={values => {
                onSubmit(values);
              }}
              render={({ values, errors, touched }) => (
                <Form>
                  <div className={classes.row}>
                    <Field name="firstName" validate={nameValidate} placeholder="First Name" label="First Name" component={textField} />
                    <Field name="lastName" validate={nameValidate} placeholder="Last Name" label="Last Name" component={textField} />
                  </div>
                  <div className={classes.row}>
                    <Field name="email" disabled validate={emailValidate} placeholder="Employee Email" label="Employee Email" component={emailField} />
                    <Field name="phone" validate={phoneValidate} label="Phone Number" component={phoneField}/>
                  </div>
                  <Button type="submit" color="secondary" variant="contained" className={classes.userPageButton} >
                    {saving ? (
                      <Loader type="Oval" height="15" color="black" width="15" />
                      ) : (
                      "Save Changes"
                    )}
                  </Button>
                </Form>
              )}
            />
          </div>
        ) : (
          <Loader type="Oval" height="15" color="#fafafa" width="15" />
        )}
    </Paper>
  );
};

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(UserPage);
