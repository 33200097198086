import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { DOWNLOAD_ENTRIES } from '../../services/store/keys';
import { exportCSVFile, createCallDetails, createReportHeaders, createFileName} from './csv';

const DownloadButton = (props) => {
    const { classes, items, allOffices, office, params, showEntriesFor} = props;
    const downLoadReport = () =>{
        let callDetails;
        let itemsFormatted = [];
        const headers = createReportHeaders(office);
        const fileName = createFileName(params, office, allOffices, showEntriesFor);
        items.forEach((item) => {
          callDetails = createCallDetails(item, allOffices, office);
          itemsFormatted.push(callDetails);
        });
        itemsFormatted = itemsFormatted.sort((a, b) => (b.collection === "New Appointment Scheduled") ? 1 : (b.collection === "Calls Answered" && a.collection !== "New Appointment Scheduled") ? 1 : -1);
        exportCSVFile(headers, itemsFormatted, fileName);
    }

    return(
        <div className={classes.root}>
            <IconButton  color="inherit" className={classes.dateRangeButton} onClick={downLoadReport}>
                <DownloadIcon className={classes.icon}/>
            </IconButton>
        </div>
    )
}


const styles = theme => ({
    root: {
      display: 'flex',
      paddingTop: 10,
      paddingRight: 5,
      marginTop: 5
    },
    icon: {
        '&:hover':{
            color: theme.palette.secondary.main
        }
    }
  });
  const mapStateToProps = (state, props) => {
    const { showEntriesFor, offices, params } = props;
    const officeSubscription = state.officeSubscription.officeSubscriptionDetails;
    const reportItems = [];
    let items = [];
    if(params.office === "all-offices") {
      offices && offices.forEach((item) => {
        if(!_.isNull(officeSubscription) && officeSubscription[item] && officeSubscription[item][showEntriesFor] && officeSubscription[item][showEntriesFor].show) {
          DOWNLOAD_ENTRIES[showEntriesFor].forEach((collection) => {
            items = state.getItems[`${item}${collection}Items`];
            if (!_.isEmpty(items)) {
              reportItems.push(items);
            }
          });
        }
      });
    } else {
      if(!_.isNull(officeSubscription) && officeSubscription[params.office] && officeSubscription[params.office][showEntriesFor] && officeSubscription[params.office][showEntriesFor].show) {
        DOWNLOAD_ENTRIES[showEntriesFor].forEach((collection) => {
          items = state.getItems[`${params.office}${collection}Items`];
          if (!_.isEmpty(items)) {
            reportItems.push(items);
          }
        });
      }
    }
    return {
        items: _.flatten(reportItems),
        office: params.office
    };
  };
  
  export default compose(
    connect(mapStateToProps),
    withStyles(styles, { withTheme: true })
  )(DownloadButton);
