import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import ArrowDown from '@material-ui/icons/ExpandMoreRounded';
import ArrowUp from '@material-ui/icons/ExpandLessRounded';
import Button from '@material-ui/core/Button';
import CallLogsDetails from "./common/CallLogsDetails";
import Collapse from '@material-ui/core/Collapse';
import itemsCollection from "./common/ItemsCollection";
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { styles } from "./common/CommonStyles";
import { withStyles } from '@material-ui/core/styles';


const ActionItems = (props) => {
  const { showEntriesFor, classes, actionItems, offeringsCategories, allOffices, params, isLoading, currentUser, completedItems} = props;
  const [open, setOpen] = useState(true);

  const [completedItemsCount, setCompletedItemsCount] = useState(0);
  useEffect(() =>{
     setCompletedItemsCount(completedItems);
  }, [completedItems]);

  if(_.isEmpty(actionItems) && !isLoading) {
    return null;
  }
  
  
  const updateCompletedTotal = (completed) =>{
    if(completed){
      if(completedItemsCount < actionItems.length){
        setCompletedItemsCount(completedItemsCount + 1);
      }
    }
    else{
      if(completedItemsCount !== 0){
        setCompletedItemsCount(completedItemsCount - 1);
      }
    }
  }
  
  const handleClick = () => {
    setOpen(!open);
  };
  
  return (
    <div>
      <Button 
        className={classes.sectionCollapseButton} 
        aria-haspopup="true" 
        onClick={handleClick}
      >
        <Typography  variant='subtitle2' color='secondary'>
          <span>action items ({actionItems.length}) complete ({completedItemsCount}) {open ? <ArrowDown viewBox="0 0 24 12"/> : <ArrowUp viewBox="0 0 24 12"/>}</span>
        </Typography>
      </Button>
      <Collapse in={open} timeout="auto" unmountinexit="true">
        <div className={classes.itemEntry}>
          <CallLogsDetails
            classes={classes}
            items={actionItems}
            offeringsCategories={offeringsCategories}
            allOffices={allOffices}
            params={params}
            isLoading={isLoading}
            user={currentUser}
            updateCompletedTotal={updateCompletedTotal}
            showEntriesFor={showEntriesFor}
          />
        </div>
      </Collapse>
    </div>
  )
};

const mapStateToProps = (state, props) => {
  const { showEntriesFor, offices, params, officeSubscription } = props;
  const { getItems } = state;
  const actionItems = itemsCollection({
    getItems, 
    showEntriesFor, 
    params, 
    offices, 
    officeSubscription, 
    entry: 'actionEntry'
  });
  
  let completedItems = 0;
  _.flatten(actionItems).forEach((item) =>{
    if(item.completedBy){
      completedItems++;
    }
  })

  return {
    offeringsCategories: _.mapKeys(state.offeringsCategories, 'objectID'),
    actionItems: _.flatten(actionItems),
    isLoading: state.loadingData.actionItems,
    currentUser: state.firebase.auth.email,
    completedItems
  };
};

export default compose(
  connect(mapStateToProps), 
  withStyles(styles, { withTheme: true })
)(ActionItems);
