import {FETCH_ITEMS, FETCH_ITEMS_FAILED, FETCH_SUMMARY, OFFICE_DOCS_SUCCESS} from "../actions/types";

const initialState = {
  count: 0,
  callsHandledCount: 0,
  officeDoc: [],
  recordNotPresent: true
};

const fetchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ITEMS:
      return { ...state, ...action.payload };

    case FETCH_ITEMS_FAILED:
      return {...state, error: action.error};

    case FETCH_SUMMARY:
      return {...state, ...action.payload};

    case OFFICE_DOCS_SUCCESS:
      return {...state, officeDoc: action.payload};

    default:
      return state;
  }
};

export default fetchReducer;