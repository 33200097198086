import { createMuiTheme } from '@material-ui/core/styles';

export const theme = (function(){
  let theme = createMuiTheme({
    typography: {
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: '#696969',
      },
      secondary: {
        main: '#ec423d',
      },
      tertiary: {
        darkest: '#112425',
        darker: '#1D3739',
        main: '#268A8F',
        greyer: '#11A1A8',
        greyest: '#31A7AD',
        lighter: '#01BCC5',
        lightest: '#3FC2C8',
      },
      success: {
        main: '#4CAF50'
      },
      text: {
        primary: '#696969',
        white: '#ffffff'
      },
    },
    textField: {
      width: '100%',
      margin: '16px auto 8px auto'
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: '8px',
        }
      },
      MuiExpansionPanel: {
        $expanded: {
          '&:first-child': {
            marginTop: 'none',
          },
          '&:last-child': {
            marginBottom: 'none',
          }
        }
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: '#696969',
          },
        },
      },
      MuiInput: {
        underline: {
          '&:after': {
            borderBottom: '2px solid #696969',
          }
        }
      },
      MuiPaper: {
        rounded: {
          borderRadius: '8px',
        },
      },
      MuiNotchedOutline: {
        root: {
          '&$focused': {
            borderColor: '#696969',
            borderWidth: '1px',
          },
        },
      },
      MuiPrivateNotchedOutline: {
        root: {
          borderRadius: '8px'
        }
      }
    },
  });

  theme.overrides.MuiTablePagination = {
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        padding: '0px 2px',
        marginTop: '15px',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
      },
    },
    input: {
      [theme.breakpoints.down('xs')]: {
        marginLeft: '-15px',
      },
    },
    selectRoot: {
      [theme.breakpoints.down('xs')]: {
        marginRight: '8px',
      },
    },
    actions: {
      [theme.breakpoints.down('xs')]: {
        marginLeft: '20px',
      },
    },
  };

  return theme;
})();
