import store from "../store"

export const completeActionItem = (id, collection, user, notes) =>{
    store.firestore.update({ collection: collection, doc: id }, {completedBy: user, unCompletedBy: null})
    .then(() =>{})
    .catch((e) => console.error(e));
  }

export const unCompleteActionItem = (id, collection, user, notes) =>{
  store.firestore.update({ collection: collection, doc: id }, {completedBy: null, unCompletedBy: user})
  .then(() =>{})
  .catch((e) => console.error(e));
}

export const updateActionNotes = (id, collection, notes) => {
  store.firestore.update({ collection: collection, doc: id }, {actionNotes: notes})
  .then(() =>{})
  .catch((e) =>{
    store.firestore.update({ collection: "callsAnswered", doc: id }, {actionNotes: notes})
  });
}

export const updateItem = (params) => {
  const {id, collection, actionNotes, makeActionItem, currentUser, changeActionItemState, wasActionItem , wasComplete, setChecked, updateCompletedTotal} = params;
  // eslint-disable-next-line
  let {singleItemCompletedBy, singleItemInCompletedBy} = params;
  let newInformation = {actionNotes};
  if(changeActionItemState){
    if(wasComplete){
      newInformation.completedBy = null;
      newInformation.unCompletedBy = currentUser;
      singleItemCompletedBy = null;
      singleItemInCompletedBy = currentUser;
      updateCompletedTotal(false);
      setChecked(false);
    }
    else{
      newInformation.unCompletedBy = null;
      newInformation.completedBy = currentUser;
      singleItemCompletedBy = currentUser;
      singleItemInCompletedBy = null;
      updateCompletedTotal(true);
      setChecked(true);
    }
  }
  if(makeActionItem){
    if(wasActionItem){
      newInformation.actionNotes = null;
      newInformation.completedBy = null;
      newInformation.unCompletedBy = null;
      newInformation.actionItem = false;
    }
    else{
      newInformation.actionItem = true;
    }
  }
  store.firestore.update({ collection: collection, doc: id }, newInformation)
  .then(() =>{})
  .catch((e) =>{
    store.firestore.update({ collection: "callsAnswered", doc: id }, newInformation)
  });
}
