export const AUTH_FETCH_USER = 'AUTH_FETCH_USER';
export const AUTH_IS_EMPLOYEE = 'AUTH_IS_EMPLOYEE';
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';
export const LOADING_DATA = 'LOADING_DATA';
export const OFFERINGS_CATEGORIES_INITIALIZE = 'OFFERINGS_CATEGORIES_INITIALIZE';
export const OFFICE_TZ_INITIALIZE = 'OFFICE_TZ_INITIALIZE';

export const OFFICE_SUBSCRIPTION = 'OFFICE_SUBSCRIPTION';

export const FETCH_ITEMS = "FETCH_ITEMS";
export const FETCH_ITEMS_FAILED = "FETCH_ITEMS_FAILED";

export const FETCH_SUMMARY = "FETCH_SUMMARY";

export const OFFICE_DOCS_SUCCESS = "OFFICE_DOCS_SUCCESS";