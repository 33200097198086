import React from "react";
import Loader from 'react-loader-spinner';
import { withStyles } from "@material-ui/core";

const LoaderWrapper = ({classes, theme: { palette: { primary }}, color, ...rest}) => {
  return (
    <div className={classes.loaderWrapper}>
      <Loader
        type="Oval"
        color={color || primary.main}
        height="20"
        width="20"
        {...rest}
      />
    </div>
  )
};

const styles = () => ({
  loaderWrapper: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    width: '100%',
    padding: "20px"
  },
});

export default withStyles(styles, { "withTheme": true })(LoaderWrapper);