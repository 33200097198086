import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles, useTheme } from '@material-ui/core/styles';
import moment from "moment-timezone";
import { Divider, useMediaQuery, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, IconButton, Grid } from "@material-ui/core";
import { fetchEntry } from './FetchEntry';
import Typography from '@material-ui/core/Typography';
import { updateItem } from '../../actions/UpdateActions'
import UrgentIcon from '@material-ui/icons/Error';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Loader from 'react-loader-spinner';

const displayGridItem = (title, text) => {
    return (
        <>
            <Grid item xs={4}>
                <b>{title}:</b>
            </Grid>
            <Grid item xs={8}>
                {text}
            </Grid>
        </>
    )
}

const SingleEntry = (props) => {
    const { openDialog, setRefresh, singleItemCompletedBy, entryInfo, classes, Offices, currentUser, singleItemId, setChecked, updateCompletedTotal, singleItemInCompletedBy} = props;
    const [open, setOpen] = useState(false);
    const [entryData, setEntryData] = useState(entryInfo);
    const [wasActionItem, setWasActionItem] = useState(false);
    const [makeActionItem, setMakeActionItem] = useState(false);
    const [wasComplete, setWasComplete] = useState(false);
    const [changeActionItemState, setChangeActionItemState] = useState(false);
    const [actionNotes, setActionNotes] = useState(null);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setEntryData(null);
        setOpen(false);
    }

    const handleSave = async () => {
        setLoading(true);
        await updateItem({id: entryInfo.id, collection: entryInfo.collection, actionNotes, makeActionItem, currentUser, changeActionItemState, wasActionItem, wasComplete, setChecked, updateCompletedTotal, singleItemCompletedBy, singleItemInCompletedBy});
        if(changeActionItemState){
            setChangeActionItemState(false);
        }
        if(makeActionItem){
            setRefresh(true);
            setMakeActionItem(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            const entry = await fetchEntry(entryInfo.id, entryInfo.collection);
            for (const office in Offices){
                if(Offices[office].id === entry.office){
                    const timeZone = Offices[office].officeInformation.timezone;
                    if(entry.appointmentTime){
                        const appointmentTime = moment.unix(entry.appointmentTime.seconds).tz(timeZone);
                        const apptDate = appointmentTime.format('dddd, MMMM D, YYYY');
                        const apptTime = appointmentTime.format('h:mm a');
                        entry.apptDate = apptDate;
                        entry.apptTime = apptTime;
                    }
                    const createdTime = moment.unix(entry.createdTime.seconds).tz(timeZone);
                    const createDate = createdTime.format('dddd, MMMM D, YYYY');
                    const createTime = createdTime.format('h:mm a');
                    entry.createDate = createDate;
                    entry.createTime = createTime;
                    entry.officeName = Offices[office].officeInformation.name
                    setWasActionItem(entry.actionItem);
                    setWasComplete(entry.completedBy ? true : false);
                    setActionNotes(entry.actionNotes ? entry.actionNotes : "");
                    const notes = (entry.reason && entry.reason.notes) ? entry.reason.notes : entry.notes ? entry.notes : entry.reasonForCall ? entry.reasonForCall : 'No notes were left on this call.';
                    entry.notes = notes;
                    setEntryData(entry);
                    setOpen(true);
                    return;
                }
            }
            setEntryData(entry);
            setOpen(true);
        }
        if(entryInfo && (singleItemId === entryInfo.id) && !openDialog){
            fetchData();
        }    
    }, [entryInfo, Offices, singleItemId, openDialog])

    return (
        entryData && entryData.patientName ?
        <Dialog fullScreen={fullScreen} maxWidth={'sm'} open={open} onClose={handleClose}>
            <DialogTitle className={classes.dialogHeader}>
            <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
                <Grid item xs={11}>
                    {entryData.urgent ? <UrgentIcon/> : null} <b>{entryData.patientName}</b>
                </Grid>
                <Grid item xs={1}>
                    <Link to={`${window.location.pathname}`}>
                        <IconButton disabled={loading ? true : false} onClick={()=>{handleClose()}} className={classes.closeButton}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Typography varient="h6" className={classes.dateName}>{` ${entryData.createDate}, ${entryData.createTime} | ${entryData.officeName}`}</Typography>
                </Grid>
            </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                    <Grid item xs={12} className={classes.sectionHeader}>
                        <b>Call Summary</b>
                    </Grid>
                    <Grid item xs={4}>
                        <b>Phone Number:</b>
                    </Grid>
                    <Grid item xs={8}>
                        ({entryData.patientPhone.substring(0,3)}) {entryData.patientPhone.substring(3, 6)}-{entryData.patientPhone.substring(6, 10)}
                    </Grid>
                    {entryData.apptDate ?
                        <>
                            <Grid item xs={4}>
                                <b>Appointment Date:</b>
                            </Grid>
                            <Grid item xs={8}>
                                {entryData.apptDate}
                            </Grid>
                            <Grid item xs={4}>
                                <b>Appointment Time:</b>
                            </Grid>
                            <Grid item xs={8}>
                                {entryData.apptTime}
                            </Grid>
                        </>
                        : null
                    }
                    {
                        entryData.reasonForCall && entryData.reasonForCall !== 'No notes were left on this call.'
                        ? displayGridItem('Reason for Call', entryData.notes )
                        : null
                    }
                    {
                        entryData.notes && !entryData.reasonForCall && entryData.notes !=='No notes were left on this call.'
                        ? displayGridItem('Call Notes', entryData.notes )
                        : null
                    }
                    {
                        entryData.actionTaken
                        ? displayGridItem('Action(s) Taken', entryData.actionTaken )
                        : null
                    }  
                    {
                        entryData.neededFollowUp
                        ? displayGridItem('Needed Follow-Up', entryData.neededFollowUp )
                        : null
                    }
                </Grid>
                {entryData.actionItem ?
                <Divider className={classes.sections}/>
                : null }
            {entryData.actionNotes || entryData.completedBy || entryData.unCompletedBy ?
                <>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                        <Grid item xs={12} className={classes.sectionHeader}>
                            <b>Action Item Summary</b>
                        </Grid>
                        {entryData.completedBy || entryData.unCompletedBy ?
                            <>
                                <Grid item xs={4}>
                                    {entryData.completedBy ? "Completed By:" : "Incompleted By:"}
                                </Grid>
                                <Grid item xs={8}>
                                    {entryData.completedBy ? entryData.completedBy: entryData.unCompletedBy}
                                </Grid>
                            </>
                        :
                        null
                        }
                        <Grid item xs={4}>
                            Action Item Notes:
                        </Grid>
                        <Grid item xs={8}>
                            {entryData.actionNotes ? actionNotes : "No notes have been left on this action item."}
                        </Grid>
                    </Grid>
                    <Divider className={classes.sections}/>
                </>
                :
                null
            }
            {entryData.actionItem ?
                <Grid container  direction="row" justify={"flex-start"} alignItems={"flex-start"} spacing={1}>
                    <Grid item xs={12} className={classes.sectionHeader}>
                        <b>Manage Entry</b>
                    </Grid>
                    <Grid item xs={6}>
                        {entryData.completedBy ? "Mark as incomplete" : "Complete action item" }
                        {changeActionItemState ? 
                            <IconButton disabled={loading ? true : false} onClick={() => {setChangeActionItemState(!changeActionItemState)}}>
                                <CheckBoxIcon className={classes.checkedBox}/>
                            </IconButton>
                            : 
                            <IconButton disabled={loading ? true : false} onClick={() => {setChangeActionItemState(!changeActionItemState)}}>
                                <CheckBoxOutlineBlankIcon />
                            </IconButton>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <TextField disabled={loading ? true : false} onChange={(e)=>{setActionNotes(e.target.value)}}variant='outlined' className={classes.notes} label={ !entryData.completedBy && !entryData.actionNotes ? "Please leave a note." : "Action Item Notes"} value={actionNotes}/>
                    </Grid>
                    <Button disabled={loading ? true : false} onClick={() => {handleSave()}}>
                     {loading ? <Loader type="Oval" color="black" height="13" width="13"></Loader> : "save" }
                    </Button>
                </Grid>
            :
            null
            }
            </DialogContent>
            <DialogActions>
                <Link to={`${window.location.pathname}`}>
                    <Button disabled={loading ? true : false} onClick={()=>{handleClose()}}>
                        close
                    </Button>
                </Link>
            </DialogActions>
        </Dialog>
        :
        null
    ) 
}

const styles = theme => ({
    dialogHeader: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white'
    },
    sectionHeader: {
        marginBottom: 15,
    },
    sections:{
        margin: 25,
    },
    closeButton: {
        color: 'white'
    },
    checkedBox: {
        color: theme.palette.secondary.main
    },
    root: {
      display: 'flex',
      paddingTop: 10,
      paddingRight: 5,
      marginTop: 5
    },
    container: {
      maxHeight: 440,
    },
    notes: {
        width: theme.textField.width,
        margin: theme.textField.margin,
        '&:hover': {
          borderColor: theme.palette.text.primary
        }
      },
  });
  const mapStateToProps = (state) => {
      return {
        currentUser: state.firebase.auth.email
      };
    }  
  export default compose(
    connect(mapStateToProps),
    withStyles(styles, { withTheme: true })
  )(SingleEntry);