import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Loader from 'react-loader-spinner';
import logo from '../../services/images/logo.png';
import qs from 'qs';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import { remail } from '../../services/validation';
import { slideUpTransitionOptions } from '../../services/transitions';
import { styles } from './styles';
import { withFirebase, isEmpty } from 'react-redux-firebase';
import { withLastLocation } from 'react-router-last-location';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

class Login extends Component {
  state = {
    logInError: false,
    passwordFieldType: "password",
    passwordButton: "show",
    loadingAfterLogin: false,
    hasLoggedInPortal: false,
    emailChecked: false,
    emailAddress: null,
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { lastLocation, redirectUri } = this.props;

    if (lastLocation && qs.parse(lastLocation.search.slice(1))['email'] && this.state.emailAddress === null) {
      this.setState({ emailAddress: qs.parse(lastLocation.search.slice(1))['email'] });
    }

    if (!isEmpty(nextProps.auth)) {
      if (
        lastLocation &&
        !lastLocation.pathname.includes('forgot-password') &&
        !lastLocation.pathname.includes('reset-password') &&
        !lastLocation.pathname.includes('login')
      ) {
        this.props.history.push(`${lastLocation.pathname}${lastLocation.search}`);
      } else if (redirectUri) {
        this.props.history.push(redirectUri);
      } else {
        this.props.history.push('/');
      }
    }
  }

  onSubmit(values) {
    const { email, password } = values;

    this.setState({
      logInError: false,
      loadingAfterLogin: true
    });

    this.props.firebase.login({
      email,
      password
    }).then(res => {
    }).catch(err => {
      this.setState({
        logInError: true,
        loadingAfterLogin: false
      });
    });
  }

  renderForgotPasswordText = () => {
    const { classes } = this.props;

      return (
        <Typography
          variant="body2"
          className={classes.forgotPasssword}
        >
          Forgot Password
        </Typography>
      );
  }

  renderTextField(field) {
    const { meta: { touched, error } } = field;

    return (
      <TextField
        label={field.label}
        className={field.className}
        margin="normal"
        type={field.type}
        autoComplete={field.fieldType === "password" ? "off" : field.autoComplete}
        error={touched && error ? true : false}
        helperText={touched && error}
        variant="outlined"
        {...field.input}
      />
    );
  }

  render() {
    const { handleSubmit, classes, lastLocation } = this.props;

    return (
      <div className={`Login ${classes.root}`}>
        <div className={classes.cardContainer}>
          <div className={classes.card}>
          <div className={classes.content}>
            <div className={classes.header}>
              <img src={logo} alt="Logo" className={classes.logo} />
            </div>
            <div>
              <ReactCSSTransitionGroup {...slideUpTransitionOptions}>
                <form
                className={classes.form}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(this.onSubmit.bind(this))}
                >
                {
                  this.state.logInError
                  ?
                  <Typography className={classes.loginError} variant='body1'>Invalid email or password.</Typography>
                  :
                  <Typography className={classes.formText} variant='body1'>Please login to view reports.</Typography>
                }
                <Field
                  name="email"
                  component={this.renderTextField}
                  label="Email"
                  type="email"
                  className={classes.textField}
                  />
                <Field
                  name="password"
                  component={this.renderTextField}
                  label="Password"
                  type="password"
                  className={classes.textField}
                  autoComplete="password"
                  />
                <div className={classes.buttonContainer}>
                  <NavLink
                    to={
                      `/forgot-password?hlip=${
                          !this.state.hasLoggedInPortal && this.state.emailChecked ?
                          'false' : 'true'
                        }${
                        lastLocation ?
                        `&redirectUri=${encodeURIComponent(`${lastLocation.pathname}${lastLocation.search}`)}`
                        : ''
                      }`
                    }
                  >
                    {this.renderForgotPasswordText()}
                  </NavLink>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    type="submit"
                    >
                    {
                      this.state.loadingAfterLogin
                      ?
                      <Loader
                         type="Oval"
                         color="#ffffff"
                         height="13"
                         width="13"
                      />
                      :
                      "Login"
                    }
                  </Button>
                </div>
              </form>
              </ReactCSSTransitionGroup>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Please enter your email address";
  }

  if (values.email) {
    if (!remail.test(values.email)) {
      errors.email = "Please enter a valid email address";
    }
  }

  if (!values.password) {
    errors.password = 'Please enter your password';
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  let params = qs.parse(ownProps.location.search.slice(1));
  let redirectUri;

  if (
    params.redirectUri &&
    !params.redirectUri.includes('login') &&
    !params.redirectUri.includes('forgot-password') &&
    !params.redirectUri.includes('reset-password')
  ) {
    redirectUri = params.redirectUri;
  } else {
    redirectUri = null;
  }

  return {
    accountId: state.accountId,
    auth: state.firebase.auth,
    empId: state.empId,
    redirectUri,
    emailAddress: params.emailAddress,
  };
};

export default compose(
  connect(mapStateToProps),
  withLastLocation,
  withRouter,
  reduxForm({
    validate,
    form: 'LoginForm',
    enableReinitialize : true
  }),
  withStyles(styles, { withTheme: true }),
  withFirebase,
)(Login);
