import _ from "lodash";
import { isEmpty, isLoaded } from "react-redux-firebase";
import { OFFICE_SUBSCRIPTION } from "./types";

const showServiceRecords = (startDate, endDate) => {
  if(!endDate) {
    return true;
  } else if (new Date(startDate).getTime() >= new Date(endDate).getTime()) {
      return false;
  }
  return true;
};

export const getOfficeSubscription = (startDate, isEmp, office) => {
  return (dispatch, getState) => {
    const offices = getState().firebase.profile ? getState().firebase.profile.offices : [];
    const officeSubscriptionDetails = {};
    let officeDetail;
    let officeOfferingService;

    if (isEmp && office) {
      officeSubscriptionDetails[office] = {
        "answering": { show: true },
        "websiteChat": { show: true },
        "recall": { show: true },
        "treatment": { show: true },
        "all-services": { show: true }
      };
    } else if(!isEmp && !_.isEmpty(offices)) {
      offices.forEach((office) => {
        if(isLoaded(getState().firestore.ordered[office]) && !isEmpty(getState().firestore.ordered[office])) {

          officeDetail = getState().firestore.ordered[office][0];
          officeOfferingService = officeDetail.offeringsTimeActive;

          let showAnsweringServiceDetails = false;
          let showWebsiteChatServiceDetails = false;
          let showRecallServiceDetails = false;
          let showScheduledTreatmentServiceDetails = false;

          if (officeOfferingService 
              && officeOfferingService.answering 
              && officeOfferingService.answering.length 
              && officeOfferingService.answering[officeOfferingService.answering.length - 1].current) {
            showAnsweringServiceDetails = showServiceRecords(startDate, officeOfferingService.answering[officeOfferingService.answering.length -1].endDate && officeOfferingService.answering[officeOfferingService.answering.length -1].endDate.seconds*1000);
          }

          if (officeOfferingService 
              && officeOfferingService.websiteChat 
              && officeOfferingService.websiteChat.length 
              && officeOfferingService.websiteChat[officeOfferingService.websiteChat.length - 1].current) {
            showWebsiteChatServiceDetails = showServiceRecords(startDate, officeOfferingService.websiteChat[officeOfferingService.websiteChat.length -1].endDate && officeOfferingService.websiteChat[officeOfferingService.websiteChat.length -1].endDate.seconds*1000);
          }

          if (officeOfferingService 
              && officeOfferingService.recall 
              && officeOfferingService.recall.length 
              && officeOfferingService.recall[officeOfferingService.recall.length - 1].current) {
            showRecallServiceDetails = showServiceRecords(startDate, officeOfferingService.recall[officeOfferingService.recall.length -1].endDate && officeOfferingService.recall[officeOfferingService.recall.length -1].endDate.seconds*1000);
          }

          if (officeOfferingService 
            && officeOfferingService.treatment 
            && officeOfferingService.treatment.length 
            && officeOfferingService.treatment[officeOfferingService.treatment.length - 1].current) {
            showScheduledTreatmentServiceDetails = showServiceRecords(startDate, officeOfferingService.treatment[officeOfferingService.treatment.length -1].endDate && officeOfferingService.treatment[officeOfferingService.treatment.length -1].endDate.seconds*1000);
          }

          officeSubscriptionDetails[office] = {
            "answering": {...(officeDetail.offeringsTimeActive.answering ? officeDetail.offeringsTimeActive.answering[officeOfferingService.answering.length -1] : null), show: showAnsweringServiceDetails},
            "websiteChat": {...(officeDetail.offeringsTimeActive.websiteChat ? officeDetail.offeringsTimeActive.websiteChat[officeOfferingService.websiteChat.length -1] : null), show: showWebsiteChatServiceDetails},
            "treatment": {...(officeDetail.offeringsTimeActive.treatment ? officeDetail.offeringsTimeActive.treatment[officeOfferingService.treatment.length -1] : null), show: showScheduledTreatmentServiceDetails},
            "recall": {...(officeDetail.offeringsTimeActive.recall ? officeDetail.offeringsTimeActive.recall[officeOfferingService.recall.length -1] : null), show: showRecallServiceDetails},
            "all-services": {current: true, show: (showAnsweringServiceDetails || showWebsiteChatServiceDetails || showRecallServiceDetails)}
          };
        }
      });
    }
    dispatch({type: OFFICE_SUBSCRIPTION, payload: officeSubscriptionDetails});
  }
};