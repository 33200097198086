import React, { Component } from 'react';
import DateRangeForm from './DateRangeForm';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

class DateRangePopover extends Component {
  render() {
    const { classes, open, anchorEl, handleClose, params, reportIsRange } = this.props;

    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={0}
      >
        <div className={classes.header}>
          <div className={classes.text}>
            <Typography variant='body1' className={classes.title}>
              Select Report Date Range
            </Typography>
          </div>
          <div className={classes.iconContainer}>
            <DateRangeIcon className={classes.icon} />
          </div>
        </div>
        <div className={classes.body}>
          <DateRangeForm params={params} reportIsRange={reportIsRange} />
        </div>
      </Popover>
    );
  }
}

const styles = theme => ({
  root: {},
  header: {
    display: 'flex',
    padding: 12,
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
  },
  text: {
    alignItems: 'center',
    marginRight: 10,
  },
  title: {
    color: theme.palette.text.white
  },
  iconContainer: {
    marginLeft: 'auto',
  },
  icon: {
    color: theme.palette.text.white
  },
  body: {
    paddingTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
  }
});

export default compose(withStyles(styles, { withTheme: true }))(DateRangePopover);
