import React from 'react';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmployee, logout } from '../actions';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

export const LogoutButton = (props) => {
  const { classes, isEmployee, logout, pageTitle } = props;

  return (
    <>
    
    <div className={classes.logoutButtonStyle}>
      <Typography className={classes.subaccountInfo}>
          {pageTitle}
      </Typography>
      <Button
        onClick={() => {
          isEmployee('');
          logout();
        }}
        variant="outlined"
        className={classes.logoutButton}>
        Logout
      </Button>
    </div>
    </>
  );
};

const styles = theme => ({
  logoutButtonStyle: {
    width: '100%',
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#fafafa',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 0
      },
  },
  logoutButton: {
    marginRight: '52px',
    color: '#fafafa',
    borderColor: '#fafafa',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
    },
  },
  subaccountInfo: {
    color: '#fafafa',
    fontSize: '25px',
    fontWeight: '700',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
     
    },
  }
});

export default compose(
  connect(null, { isEmployee, logout }),
  withStyles(styles, { withTheme: true })
)(LogoutButton);
