import React, { Component } from 'react';
import logo from '../services/images/logo.png';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

class MissingInfo extends Component {

  render() {
    const { classes } = this.props;
    document.title = 'CallForce Portal';

    return (
      <div className={`Home ${classes.root}`}>
        <div className={classes.header}>
          <img src={logo} alt="Logo" className={classes.logo} />
        </div>
        <div className={classes.reportTextContainer}>
          <Typography variant="subtitle1" className={`${classes.reportText} ${classes.errorText}`}>
            Your request is missing the information needed to display a report.
            Please make sure you are following the link provided in your email.
            If you continue to have issues, please contact CallForce at
            <a href="tel:8019010502"> (801) 901-0502</a>.
          </Typography>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    margin: '80px auto 100px auto',
    padding: '0px 10px 150px 10px',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
      maxWidth: 1100,
    },
  },
  controls: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  header: {
    height: '60px',
    marginBottom: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    display: 'block',
    width: '215px',
    [theme.breakpoints.up('sm')]: {
      width: '30%',
      maxWidth: '250px',
    },
  },
});

export default compose(withStyles(styles, { withTheme: true }))(MissingInfo);
