import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, isLoaded } from "react-redux-firebase";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ExpandLessSharp, ExpandMoreSharp } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BusinessIcon from '@material-ui/icons/Business';
import ListItemIcon from '@material-ui/core/ListItemIcon';


import { transitionOptions } from '../services/transitions';

class AccountInfo extends Component {
  state = {
    officeDropDown: false,
  };

  toggleOfficeDropdown = () => {
    this.setState({ officeDropDown: !this.state.officeDropDown });
  };

  onChangeToggle = (officeId) => {
    const { params, history } = this.props;
    const { reportDate, reportDateStart, reportDateEnd } = params;
    if(reportDate) {
      history.push(`/${officeId}/${reportDate}`);
      this.toggleOfficeDropdown();
    } else {
      history.push(`/${officeId}/${reportDateStart}/${reportDateEnd}`);
      this.toggleOfficeDropdown();
    }
  };

  renderOfficeDropdown() {
    const { classes, allOffices, isEmp } = this.props;
    const { officeDropDown } = this.state;

    if(!(allOffices && allOffices.length > 1) || isEmp) {
      return null;
    }

    return (
      <div className={classes.dropdownIcon}>
        {officeDropDown ?
          <ExpandLessSharp /> :
          <ExpandMoreSharp />
        }
      </div>
    )
  }


  renderTop() {
    const {classes, office, allOffices, params, isEmp} = this.props;
    const {officeDropDown} = this.state;

    const officeName = (office && office.length) ? office[0].officeInformation.name : "All Offices";

    return (
        <List component="div" disablePadding={true} classes={{root: classes.root}} >
          <ListItem button onClick={() => this.toggleOfficeDropdown()} classes={{root: classes.officeListItem}} className={isEmp ? "empOffice" : ""} >
            <ListItemIcon><BusinessIcon/></ListItemIcon>
            <ListItemText primary={officeName} classes={{root: classes.officeListItemText}} />
              {this.renderOfficeDropdown()}
          </ListItem>
          { !isEmp ?
            <Collapse in={officeDropDown} timeout="auto" unmountOnExit>
            <ClickAwayListener onClickAway={() => this.toggleOfficeDropdown()}>
              <List component="div" disablePadding={true} classes={{root: classes.collapseList}}>
                <ListItem
                  button
                  onClick={() => this.onChangeToggle("all-offices")}
                  classes={{root: classes.collapseListItem}}
                  className={params.office === "all-offices" ? "active" : ""}
                >
                  <ListItemText primary="All Offices" classes={{root: classes.collapseListItemName}}/>
                </ListItem>
                {
                  allOffices.map((link, index) => {
                    return (
                      <ListItem
                        button
                        key={index}
                        onClick={() => this.onChangeToggle(link.id)}
                        classes={{root: classes.collapseListItem}}
                        className={params.office === link.id ? "active" : ""}
                      >
                        <ListItemText primary={link.officeInformation.name}
                          classes={{root: classes.collapseListItemName}}
                        />
                      </ListItem>
                    )
                  })
                }
              </List>
            </ClickAwayListener>
          </Collapse> :
            null
          }
      </List>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <ReactCSSTransitionGroup {...transitionOptions}>
        <div className={classes.account}>
          <Typography variant="caption" className={classes.officeName}>
            {this.renderTop()}
          </Typography>
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}

const styles = theme => ({
  account: {
    display: 'flex',
    paddingLeft: '24px',
    paddingRight:'24px',
    position: 'relative',
  },
  officeName: {
    color: '#fafafa',
  },
  root: {
    minWidth: '222px',
  },
  officeListItem: {
    padding: '0 10px',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '&.empOffice': {
      cursor: 'default'
    }
  },
  officeListItemText: {
    padding: 0,
    '& span': {
      color: '#696969',
      fontSize: '15px',
      fontWeight: '700',
    },
  },
  dropdownIcon: {
    padding: 0,
    marginTop: '5px',
    color: '#696969'
  },
  collapseList: {
    zIndex: '10',
    padding: 0,
    position: 'relative',
    backgroundColor: '#fafafa',
    borderRadius: '5px',
    boxShadow: '0 5px 34px 0 rgba(0, 0, 0, 0.3)',
  },
  collapseListItem: {
    '&.active': {
      backgroundColor: '#ec423d',
      '&:first-child': {
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px'
      },
      '&:last-child': {
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px'
      }
    },
    '&.active span': {
      color: '#fafafa',
      fontWeight: 'bold',
    },
  },
  collapseListItemName: {
    padding: 0,
    '& span': {
      color: '#696969',
    },
  },
});

const mapStateToProps = (state, props) => {
  const { offices, params } = props;
  const allOffices = [];

  if(isLoaded(state.firestore.ordered) && !isEmpty(state.firestore.ordered)) {
    offices && offices.forEach((item) => {
      if(!isEmpty(state.firestore.ordered[item])) {
        allOffices.push(state.firestore.ordered[item][0])
      }
    });
  }

  return {
    auth: state.firebase.auth,
    office: state.firestore.ordered[params.office],
    allOffices: allOffices
  }
};

export default compose(connect(mapStateToProps), withStyles(styles, { withTheme: true }))(AccountInfo);
