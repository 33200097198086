import _ from 'lodash';
import React, { Component } from 'react';
import ActionItems from './ActionItems';
import CallsAnsweredItems from './CallsAnsweredItems';
import NotScheduledItems from './NotScheduledItems';
import ScheduledTreatmentItems from './ScheduledTreatmentItems';
import ReportText from './ReportText';
import ScheduledItems from './ScheduledItems';
import Summary from './Summary';
import { connect } from 'react-redux';
import { ENTRIES } from '../services/store/keys';
import { getOfficeSubscription, fetchItemsForAllCollections } from '../actions';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import WebsiteChatItems from './WebsiteChatItems';

const COMPONENTS = {
  "scheduled": ScheduledItems,
  "notScheduled": NotScheduledItems,
	"callsAnswered": CallsAnsweredItems,
	"websiteChat": WebsiteChatItems,
  "scheduledTreatment": ScheduledTreatmentItems,
};

class Report extends Component {
  state = {
    recallUpdated: false,
    allOfficesHaveSubscriptions: true
	};
	
	componentDidMount() {
		const { 
			params, 
			reportIsRange, 
			isEmp, 
			showEntriesFor,
			offices,
			fetchItemsForAllCollections, 
			getOfficeSubscription
		} = this.props;
		let startDate = reportIsRange ? params.reportDateStart : params.reportDate;
		
		getOfficeSubscription(startDate, isEmp, params.office);
    	fetchItemsForAllCollections({
			params, 
			dateRange: reportIsRange, 
			showEntriesFor, 
			offices, 
			isEmp
		});
	}

	componentDidUpdate(prevProps) {
    const { 
			showEntriesFor,
			params, 
			offices, 
			isEmp, 
			reportIsRange, 
			fetchItemsForAllCollections, 
			getOfficeSubscription
		} = this.props;

		if (
			(!_.isEqual(params, prevProps.params) || 
			!_.isEqual(showEntriesFor, prevProps.showEntriesFor))
			&& showEntriesFor === 'recall' && !this.state.recallUpdated) {
			this.setState({recallUpdated: true});
		}
		if (
			!_.isEqual(params, prevProps.params) || 
			!_.isEqual(showEntriesFor, prevProps.showEntriesFor)
		) {
			let startDate = reportIsRange 
				? params.reportDateStart 
				: params.reportDate;

      getOfficeSubscription(startDate, isEmp, params.office);
      fetchItemsForAllCollections({
				params, 
				dateRange: reportIsRange, 
				showEntriesFor, 
				offices, 
				isEmp
			});
    }
    
    if (this.props.params.office === 'all-offices' 
    && Object.keys(this.props.officeSubscription).length > 0 
    && this.props.showEntriesFor !== prevProps.showEntriesFor) {
      this.setState({allOfficesHaveSubscriptions: this.checkAllOfficesForSubs(this.props.showEntriesFor, this.props.officeSubscription)})
    }
  }

  checkAllOfficesForSubs(showEntriesFor, officeSubscription) {
    let show = false;
    Object.keys(officeSubscription).forEach((subscription) => {
      if(officeSubscription[subscription][showEntriesFor].show){
        show = true;
      };
    });
    return show;
  }

	renderComponents() {
	  const {
			showEntriesFor,
			params, 
			reportIsRange, 
			offices, 
			officeSubscription, 
			isEmp, 
      allOffices
		} = this.props;
		const itemsToShow = ENTRIES[showEntriesFor];
		
    return (
	    <div>
				{
					itemsToShow.map((item, index) => {
            const EntryComponent = COMPONENTS[item];
            const entryCollection = this.formatEntryCollection(showEntriesFor)
           
						if(!EntryComponent) return null;
            
						return (
							<EntryComponent
								isEmp={isEmp}
								dateRange={reportIsRange}
								key={index}
								params={params}
								showEntriesFor={showEntriesFor}
								offices={offices}
								officeSubscription={officeSubscription}
                allOffices={allOffices}
                entryCollection={entryCollection}
							/>
						)
					})
				}
      </div>
    )
  }

  formatEntryCollection (tab) {
    if (tab === 'answering') {
      return 'callsAnswered';
    } else if (tab === 'recall') {
      return 'scheduled';
    } else if (tab === 'treatment') {
      return 'scheduledTreatment';
    }
  }

  displayEntries () {
		const { 
			params,
			showEntriesFor,
			reportIsRange,  
			office, 
			offices, 
			officeSubscription, 
			isEmp, 
			allOffices 
		} = this.props;

		if((!isLoaded(office) || isEmpty(office) || 
			(
				(office && !_.isNull(officeSubscription)) 
				&& (
					(!(officeSubscription[params.office] && officeSubscription[params.office].answering && officeSubscription[params.office].answering.show) && showEntriesFor === "answering") 
					|| 
					(!(officeSubscription[params.office] && officeSubscription[params.office].recall && officeSubscription[params.office].recall.show) && showEntriesFor === "recall")
					|| 
					(!(officeSubscription[params.office] && officeSubscription[params.office].treatment && officeSubscription[params.office].treatment.show) && showEntriesFor === "treatment")
					)
			)
			) && params.office !== "all-offices") {
			return null;
		}

		return (
			<span>
				<Summary
					dateRange={reportIsRange}
					params={params}
					showEntriesFor={showEntriesFor}
					offices={offices}
					officeSubscription={officeSubscription}
          recallUpdated={this.state.recallUpdated}
          allOfficesHaveSubscriptions={this.state.allOfficesHaveSubscriptions}
          allOffices={allOffices}
				/>
				<ActionItems
					isEmp={isEmp}
					dateRange={reportIsRange}
					params={params}
					showEntriesFor={showEntriesFor}
					offices={offices}
					officeSubscription={officeSubscription}
					allOffices={allOffices}
				/>
				{this.renderComponents()}
			</span>
		)
	}
	
	render() {
		const { 
			params,
			showEntriesFor,
			reportIsRange, 
			officeSubscription 
		} = this.props;

		return (
			<span>
				<ReportText
					authorizedUser={true}
					dateRange={reportIsRange}
					params={params}
					showEntriesFor={showEntriesFor}
          officeSubscription={officeSubscription}
          allOfficesHaveSubscriptions={this.state.allOfficesHaveSubscriptions}
				/>
				{this.displayEntries()}
			</span>
		);
	}
}


const mapStateToProps = (state, { params }) => {
  return {
    office: state.firestore.ordered[params.office],
	  officeSubscription: state.officeSubscription.officeSubscriptionDetails,
  };
};

export default connect(
	mapStateToProps, 
	{ getOfficeSubscription, fetchItemsForAllCollections }
)(Report);
