import store from "../../store";

export const fetchEntry = async (entryId, collection) =>{
    try {
        const entryData = await store.firestore.collection(collection).doc(entryId).get();
        if(entryData.data()){
            return entryData.data();
        }
    }
    catch {
        const entryData = await store.firestore.collection("callsAnswered").doc(entryId).get();
        if(entryData.data()){
            return entryData.data();
        }
    }
}