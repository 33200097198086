import { AUTH_FETCH_USER, AUTH_IS_EMPLOYEE } from '../actions/types';

export const fetchUser = (state=null, action) => {
  switch (action.type) {
    case AUTH_FETCH_USER:
      return action.payload;
    default:
      return state;
  }
}

export const isEmp = (state=null, action) => {
  switch (action.type) {
    case AUTH_IS_EMPLOYEE:
      return action.payload;
    default:
      return state;
  }
}

export const AuthReducer = {
  fetchUser,
  isEmp
};
