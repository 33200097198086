import { OFFICE_SUBSCRIPTION } from "../actions/types";

const initialState = {
  officeSubscriptionDetails: null
};

export default (state=initialState, action) => {
  switch (action.type) {
    case OFFICE_SUBSCRIPTION:
      return { ...state, officeSubscriptionDetails: action.payload };
    default:
      return state;
  }
};