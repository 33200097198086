import _ from 'lodash';
import { authRef } from "../store";
import algoliasearch from 'algoliasearch';
import {
  AUTH_FETCH_USER,
  AUTH_IS_EMPLOYEE,
  AUTH_LOGOUT_USER
} from './types';

export const fetchUser = () => {
  return (dispatch) => {
    authRef.onAuthStateChanged(user => {
      if (user) {
        dispatch({ type: AUTH_FETCH_USER, payload: user });
      } else {
        dispatch({ type: AUTH_FETCH_USER, payload: null });
      }
    });
  };
};

export const isEmployee = (employee) => {
  const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID, 
    process.env.REACT_APP_ALGOLIA_BROWSE_EMPLOYEES_KEY
  );
  const index = algoliaClient.initIndex(
    process.env.REACT_APP_ALGOLIA_EMPLOYEE_INDEX_NAME
  );
  const browser = index.browseAll();
  let hits = [];

  return (dispatch) => {
    let employees;

    browser.on('result', (content) => { hits = hits.concat(content.hits) });
    browser.on('end', () => {
      employees = _.map(hits, 'objectID');
      dispatch({ type: AUTH_IS_EMPLOYEE, payload: employees.includes(employee) });
    });
    browser.on('error', function onError(err) { throw err });
  };
};

export const logout = () => {
  return (dispatch) => {
    authRef.signOut()
      .then(() => {
        dispatch({ type: AUTH_LOGOUT_USER });
      })
      .catch(error => {
        console.error(error);
      });
  };
};
