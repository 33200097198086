import React, { Component } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateRangePopover from './DateRangePopover';
import IconButton from '@material-ui/core/IconButton';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

class DateRangePicker extends Component {
  constructor() {
    super();

    this.state = {
      anchorEl: null,
      openPopover: false,
    };
  }


  UNSAFE_componentWillUpdate(nextProps) {
    const { params } = this.props;
    const dateChanged = params.reportDate !== nextProps.params.reportDate
      || params.reportDateStart !== nextProps.params.reportDateStart
      || params.reportDateEnd !== nextProps.params.reportDateEnd;

    if (dateChanged) {
      this.setState({ openPopover: false });
    }
  }

  handleClick = event => {
    const { currentTarget } = event;

    this.setState({
      anchorEl: currentTarget,
      openPopover: !this.state.openPopover,
    });
  };

  handleClose() {
    this.setState({ openPopover: false });
  }

  render() {
    const { classes, params, reportIsRange } = this.props;
    const { anchorEl, openPopover } = this.state;

    return (
      <div className={classes.root}>
        <IconButton
          color="inherit"
          className={classes.dateRangeButton}
          aria-label="Select Report Date Range"
          onClick={this.handleClick}
          >
          <DateRangeIcon className={classes.icon} />
        </IconButton>
        <DateRangePopover
          open={openPopover}
          anchorEl={anchorEl}
          handleClose={() => this.handleClose()}
          params={params}
          reportIsRange={reportIsRange}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    paddingTop: 10,
    paddingRight: 5,
  },
  dateRangeButton: {
    marginLeft: 'auto',
  },
  icon: {
    color: theme.palette.secondary.main
  }
});

export default compose(withStyles(styles, { withTheme: true }))(DateRangePicker);
