import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import { reactReduxFirebase, firebaseReducer } from 'react-redux-firebase';
import { reduxFirestore, firestoreReducer } from 'redux-firestore';
import { reducer as formReducer } from 'redux-form';
import { AuthReducer } from './reducers/AuthReducer';
import LoadingReducer from './reducers/LoadingReducer';
import OfferingsCategoriesReducer from './reducers/OfferingsCategoriesReducer';
import OfficeSubscriptionReducer from "./reducers/OfficeSubscriptionReducer";
import FetchReducer from "./reducers/FetchReducer";
import { config } from './services/firebase';
import thunk from 'redux-thunk';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

const rrfConfig = {
  userProfile: 'accounts',
  // attachAuthIsReady: true,
  useFirestoreForProfile: true
};

// Initialize firebase instance
firebase.initializeApp(config);

// Initialize other services on firebase instance
firebase.analytics();
firebase.auth();
firebase.storage();
firebase.firestore().settings({});

// Export authRef and provider and storage
export const authRef = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
export const storage = firebase.storage();
export const db = firebase.firestore();


export const functions = () => {
  let fn = firebase.functions();
  /**
   * Use the line below for testing Firebase functions locally.
   */
  // fn.useFunctionsEmulator("http://localhost:5001");
  return fn;
};

// Add reactReduxFirebase enhancer when making store creator
const createStoreWithFirebase = compose(
  reduxFirestore(firebase),
  reactReduxFirebase(firebase, rrfConfig),
  applyMiddleware(thunk),
)(createStore);

// Combine reducers
const rootReducer = combineReducers({
  ...AuthReducer,
  loadingData: LoadingReducer,
  offeringsCategories: OfferingsCategoriesReducer,
  officeSubscription: OfficeSubscriptionReducer,
  getItems: FetchReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  form: formReducer
});

const store = createStoreWithFirebase(rootReducer);

export default store;
