import moment from "moment-timezone";
import _ from "lodash";
import {isEmpty} from "react-redux-firebase";

import {FETCH_ITEMS, FETCH_ITEMS_FAILED, OFFICE_DOCS_SUCCESS} from "./types";
import { loadingData } from "./LoadingActions";
import store from "../store";
import { ENTRIES, NEW_COLLECTIONS } from '../services/store/keys';
import {getSummaryStatsNew} from "./StatsActions"

const getOfficeDoc = (office) => {

  return (dispatch, getState) => {
    const { firestore: {ordered} } = getState();

    if (!isEmpty(ordered[office])) {
      return ordered[office];
    }
    return null;
  }
};

const getItemsForCollection = ({collection, start, end, office}) => {
  return new Promise((resolve, reject) => {
    store.firestore.get({
      collection: collection === "reScheduled" ? "rescheduled" : collection,
      where: [
        ['office', '==', office],
        ['createdTime', '>=', start],
        ['createdTime', '<', end],
      ],
      orderBy: [['createdTime']],
    }).then((response) => {
      const data = response.docs.map((res) => {
        return { id: res.id,...res.data()};
      });

      const collectionActionItems = data.filter((item) => item.actionItem);

      resolve({[office + NEW_COLLECTIONS[collection].itemsKey]: data, [office + NEW_COLLECTIONS[collection].actionItemsKey]: collectionActionItems});

    }).catch((error) => {
      reject(error);
    });
  })
};

export const getItemsForOfficeCollection = ({params, dateRange, showEntriesFor}) => {
  const { office } = params;

  let start, end;
  if(dateRange) {
    start = moment(params.reportDateStart).startOf('day').toDate();
    end = moment(params.reportDateEnd).endOf('day').toDate();
  } else {
    start = moment(params.reportDate).startOf('day').toDate();
    end = moment(params.reportDate).endOf('day').toDate();
  }

  return (dispatch) => {
    let promises = [];
    ENTRIES[showEntriesFor].forEach((collection) => {
      dispatch(loadingData(collection, true));
      promises.push(getItemsForCollection({collection, start, end, office}));
    });

    return promises;
  };
};

const resolveAllPromises = ({promises, showEntriesFor}) => {
  return (dispatch) => {
    Promise.all(promises).then((response) => {
      const data = Object.assign({}, ...response);
      dispatch({type: FETCH_ITEMS, payload: data});

      ENTRIES[showEntriesFor].forEach((collection) => {
        dispatch(loadingData(collection, false));
      });

      dispatch(loadingData('actionItems', false));
    }).catch((error) => {
      dispatch({type: FETCH_ITEMS_FAILED, error: error.message});
    });
  }
};

export const fetchItemsForAllCollections = ({params, dateRange, showEntriesFor, offices, isEmp}) => {
  return (dispatch, getState) => {

    dispatch(loadingData('summary', true));
    dispatch(loadingData('actionItems', true));

    const { office } = params;
    const { officeSubscription: {officeSubscriptionDetails}} = getState();
    let promises = [];
    let officeDocs = [];    
    if((isEmp && office) || office !== "all-offices") {
      if(!_.isNull(officeSubscriptionDetails) && officeSubscriptionDetails[office] && officeSubscriptionDetails[office][showEntriesFor] && officeSubscriptionDetails[office][showEntriesFor].show) {
        getSummaryStatsNew({params, office, showEntriesFor, dateRange})
        promises.push(dispatch(getItemsForOfficeCollection({params, dateRange, showEntriesFor})));
        if (!_.isNull(dispatch(getOfficeDoc(office)))) {
          officeDocs.push(dispatch(getOfficeDoc(office)));
        }
      } else {
        dispatch(loadingData("summary", false));
      }
    } else {
      offices.forEach((office) => {
        if(!_.isNull(officeSubscriptionDetails) && officeSubscriptionDetails[office] && officeSubscriptionDetails[office][showEntriesFor] && officeSubscriptionDetails[office][showEntriesFor].show) {
          getSummaryStatsNew({params, office, showEntriesFor, dateRange})
          promises.push(dispatch(getItemsForOfficeCollection({params: {...params, office}, dateRange, showEntriesFor})));
          if (!_.isNull(dispatch(getOfficeDoc(office)))) {
            officeDocs.push(dispatch(getOfficeDoc(office)));
          }
        }
      });
    }

    dispatch(resolveAllPromises({promises: _.flatten(promises), showEntriesFor}));
    dispatch({type: OFFICE_DOCS_SUCCESS, payload: _.isEmpty(officeDocs) ? null : _.flatten(officeDocs)});
  }
};
