import store from "../store";
import moment from 'moment-timezone';
import { STATS_ENTRIES } from '../services/store/keys';
import {FETCH_SUMMARY} from "./types";
import { loadingData } from "./LoadingActions";

export const getSummaryStatsNew = ({params, office, showEntriesFor, dateRange}) => {
    const count = 0;
    const callsHandledCount = 0;
    const recordNotPresent = true;
    let start;
    let end;
    store.dispatch({type: FETCH_SUMMARY, payload: {count, callsHandledCount, recordNotPresent}});
    if(dateRange) {
        start = moment(params.reportDateStart).startOf('day').toDate();
        end = moment(params.reportDateEnd).endOf('day').toDate();
      } else {
        start = moment(params.reportDate).startOf('day').format('YYYY-MM-DD');
        end = moment(params.reportDate).endOf('day').format('YYYY-MM-DD');
      }
    STATS_ENTRIES[showEntriesFor].forEach(async entry => {
        getEntryStats({start, end, entry, office, recordNotPresent, showEntriesFor});
    });
}

const getEntryStats = ({start, end, entry, office, recordNotPresent}) => {

    let subQuery =  {
        collection: entry,
        doc: "daily"
    };

    if (entry === "scheduledCallsAnswered") {
        subQuery = {
            collection: entry,
            doc: "callsAnswered",
            subcollections: [{
                collection: "statistics",
                doc: "daily"
            }]
        }
    }

    if (entry === "scheduledWebsiteChat") {
        subQuery = {
            collection: entry,
            doc: "websiteChat",
            subcollections: [{
                collection: "statistics",
                doc: "daily"
            }]
        }
    }

    store.firestore.get({
        collection: 'statistics',
        doc: 'clients',
        subcollections: [{
            collection: office,
            doc: 'statistics',
            subcollections: [subQuery]
        }]
    }).then((response) => {
        const dailyStats = response.data();
        if (dailyStats) {
            recordNotPresent = false;
            let callsHandledCount = store.getState().getItems["callsHandledCount"];
            let count = store.getState().getItems["count"];
            if (start === end) {
                if (dailyStats[start]) {
                    if (entry === "scheduledCallsAnswered" || entry === "scheduledWebsiteChat") {
                        count = count + dailyStats[start];
                    } else if (entry === "scheduled" || entry === "scheduledTreatment") {
                        const allItems = store.getState().getItems;
                        const treatmentItems = [];
                        const recallItems = [];
                        
                        for (let key in allItems) {
                            if (key.includes('notScheduled')) {
                                const treatments = allItems[key].filter(treatment => treatment.entryType === 'treatment');
                                treatmentItems.push(...treatments);

                                const recalls = allItems[key].filter(recall => recall.entryType === 'recall');
                                recallItems.push(...recalls);
                            }
                        }
                        count = count + dailyStats[start];
                        callsHandledCount = entry === "scheduled" ? count - treatmentItems.length : count + treatmentItems.length;
                    } else {
                        callsHandledCount = callsHandledCount + dailyStats[start];
                    }
                }
            } else {
                for(let day in dailyStats) {
                    if ((moment(day) >= moment(start)) && (moment(day) <= moment(end))) {
                        if (dailyStats[day]) {
                            if (entry === "scheduledCallsAnswered" || entry === "scheduledWebsiteChat") {
                                count = count + dailyStats[day];
                            } else if (entry === "scheduled" || entry === "scheduledTreatment") {
                                const allItems = store.getState().getItems;
                                const treatmentItems = [];
                                const recallItems = [];

                                for (let key in allItems) {
                                    if (key.includes('notScheduled')) {
                                        const treatments = allItems[key].filter(treatment => treatment.entryType === 'treatment');
                                        treatmentItems.push(...treatments);

                                        const recalls = allItems[key].filter(recall => recall.entryType === 'recall');
                                        recallItems.push(...recalls);
                                    }
                                }
                                
                                count = count + dailyStats[day];
                                callsHandledCount = entry === "scheduled" ? count - treatmentItems.length : count + treatmentItems.length;
                            } else {
                                callsHandledCount = callsHandledCount + dailyStats[day];
                            }
                        }
                    }
                }
            }
            store.dispatch({type: FETCH_SUMMARY, payload: {count, callsHandledCount, recordNotPresent}});
            store.dispatch(loadingData('summary', false));
        } else {
            store.dispatch(loadingData('summary', false));
        }
    }).catch(err => console.error('Failed to get statistics from firebase', err));
}