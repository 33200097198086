import React, { Component } from 'react';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Loader from "./common/Loader";
import _ from "lodash";
import { isLoaded } from "react-redux-firebase";

class ReportText extends Component {
  reportDateRangeText(name) {
    const { dateRange, params } = this.props;

    if (dateRange) {
      document.title = 'CallForce Portal | '
        + name
        + ' - Report for '
        + moment(params.reportDateStart).format('MM/DD/YYYY')
        + ' to '
        + moment(params.reportDateEnd).format('MM/DD/YYYY');

      const start = moment(params.reportDateStart).format('MMMM D, YYYY');
      const end = moment(params.reportDateEnd).format('MMMM D, YYYY');

      return (
        <span>between <b>{start}</b> and <b>{end}</b></span>
      );
    } else {
      const date = moment(params.reportDate).format('MMMM D, YYYY');
      document.title = 'CallForce Portal | '
        + name
        + ' - Report for '
        + moment(params.reportDate).format('MM/DD/YYYY');

      return (
        <span>on <b>{date}</b></span>
      );
    }
  }

  renderText() {
    const { authorizedUser, classes, office, showEntriesFor, params, officeSubscription, profile, allOfficesHaveSubscriptions } = this.props;

    document.title = 'CallForce Portal';

    if (authorizedUser) {
      if(params.office === "all-offices") {
        if(!allOfficesHaveSubscriptions){
          return (
            <Typography variant="subtitle1" className={classes.reportText}>
              None of your offices are currently subscribed to this service.
              If you are interested in using this service and would like to learn more, or if you believe this is an error,
              please reach out to us at <a href="tel:8014778287">(801) 477-8287</a>.
            </Typography>
          );
        } else {
          return (
            <Typography variant="subtitle1" className={classes.reportText}>
              The following report is for <b>All Offices</b> for
              calls made {this.reportDateRangeText("All Offices")}. For any questions
              about this report, please contact CallForce at <a href="tel:8019010502">(801) 901-0502</a>.
            </Typography>
          )
        }
      } 

      if (!_.isNull(officeSubscription) && !_.isUndefined(officeSubscription) && !_.isEmpty(officeSubscription) && office) {
        if (!officeSubscription[params.office].answering.show && showEntriesFor === 'answering') {
          if (officeSubscription[params.office].answering.endDate) {
            let date = moment(new Date(officeSubscription[params.office][showEntriesFor].endDate.seconds*1000)).format("MMM D, YYYY");

            return (
              <Typography variant="subtitle1" className={classes.reportText}>
                You are no longer subscribed for <b>Answering</b> service. Our records show that this service was ended on <b>{date}</b>.
                If you would like to continue service, call <a href="tel:8014778287">(801) 477-8287</a>. If you would like to view your past entries,
                please adjust the date range to before the service was ended.
              </Typography>
            );
          }
          return (
            <Typography variant="subtitle1" className={classes.reportText}>
              You are not currently subscribed to our <b>Answering</b> service.
              If you are interested in using our Answering service or would like to learn more,
              reach out to us at <a href="tel:8014778287">(801) 477-8287</a>.
            </Typography>
          );
        } else if (!officeSubscription[params.office].recall.show && showEntriesFor === 'recall') {
          if (officeSubscription[params.office].recall.endDate) {
            let date = moment(new Date(officeSubscription[params.office][showEntriesFor].endDate.seconds*1000)).format("MMM D, YYYY");

            return (
              <Typography variant="subtitle1" className={classes.reportText}>
                You are no longer subscribed for <b>Recall</b> service. Our records show that this service was ended on <b>{date}</b>.
                If you would like to continue service, call <a href="tel:8014778287">(801) 477-8287</a>. If you would like to view your past entries,
                please adjust the date range to before the service was ended.
              </Typography>
            );
          }
          return (
            <Typography variant="subtitle1" className={classes.reportText}>
              You are not currently subscribed to our <b>Recall</b> service.
              If you are interested in using our Recall service or would like to learn more,
              reach out to us at <a href="tel:8014778287">(801) 477-8287</a>.
            </Typography>
          );
        } else if (!officeSubscription[params.office].treatment.show && showEntriesFor === 'treatment') {
          if (officeSubscription[params.office].treatment.endDate) {
            let date = moment(new Date(officeSubscription[params.office][showEntriesFor].endDate.seconds*1000)).format("MMM D, YYYY");

            return (
              <Typography variant="subtitle1" className={classes.reportText}>
                You are no longer subscribed for <b>Scheduled Treatment</b> service. Our records show that this service was ended on <b>{date}</b>.
                If you would like to continue service, call <a href="tel:8014778287">(801) 477-8287</a>. If you would like to view your past entries,
                please adjust the date range to before the service was ended.
              </Typography>
            );
          }
          return (
            <Typography variant="subtitle1" className={classes.reportText}>
              You are not currently subscribed to our <b>Scheduled Treatment</b> service.
              If you are interested in using our Scheduled Treatment service or would like to learn more,
              reach out to us at <a href="tel:8014778287">(801) 477-8287</a>.
            </Typography>
          );
        } else if (!officeSubscription[params.office].websiteChat.show && showEntriesFor === 'websiteChat') {
          if (officeSubscription[params.office].websiteChat.endDate) {
            let date = moment(new Date(officeSubscription[params.office][showEntriesFor].endDate.seconds*1000)).format("MMM D, YYYY");

            return (
              <Typography variant="subtitle1" className={classes.reportText}>
                You are no longer subscribed for <b>Website Chat</b> service. Our records show that this service was ended on <b>{date}</b>.
                If you would like to continue service, call <a href="tel:8014778287">(801) 477-8287</a>. If you would like to view your past entries,
                please adjust the date range to before the service was ended.
              </Typography>
            );
          }
          return (
            <Typography variant="subtitle1" className={classes.reportText}>
              You are not currently subscribed to our <b>Website Chat</b> service.
              If you are interested in using our Website Chat service or would like to learn more,
              reach out to us at <a href="tel:8014778287">(801) 477-8287</a>.
            </Typography>
          );
        }
        return (
          <Typography variant="subtitle1" className={classes.reportText}>
            The following report is for <b>{office[0].officeInformation.name}</b> for
            calls made {this.reportDateRangeText(office[0].officeInformation.name)}. For any questions
            about this report, please contact CallForce at <a href="tel:8019010502">(801) 901-0502</a>.
          </Typography>
        );
      }

      return (
        <Loader />
      );
    } else {

      if(isLoaded(profile) && !(profile && profile.offices)) {
        return (
          <Typography variant="subtitle1" className={classes.reportText}>
            You are not subscribed to any services. If you believe this is an error, please contact <a href="tel:8019010502">(801) 901-0502</a>.
          </Typography>
        );
      }

      return (
        <Typography variant="subtitle1" className={`${classes.reportText} ${classes.errorText}`}>
          You do not have permissions to view this report. Please make sure you are following
          the link provided in your email. If you continue to have issues, please contact
          CallForce at <a href="tel:8019010502">(801) 901-0502</a>.
        </Typography>
      );
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.reportTextContainer}>
        {this.renderText()}
      </div>
    );
  }
}

const styles = theme => ({
  reportTextContainer: {
    padding: '0 5px',
  },
  reportText: {
    padding: '0 2px',
  },
  errorText: {
    marginTop: '15px',
  },
});

const mapStateToProps = (state, props) => {
  const { params } = props;
  return {
    office: state.firestore.ordered[params.office]
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(ReportText);