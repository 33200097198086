import React, {useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles, Dialog } from "@material-ui/core";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';
import { completeActionItem, unCompleteActionItem, updateActionNotes } from '../actions/UpdateActions'
import { fetchEntry } from "./SingleEntry/FetchEntry"

const ActionItemDialog = ({showEntriesFor, singleItem, isOpen, openDialog, complete, id , user, collection, setChecked, updateCompletedTotal}) =>{
    const [notes, setNotes] = useState("");
    const locationPath = window.location.pathname;

    const handleClose = () => {
        openDialog(false);
    };

    const takeAction = () => {
        if(complete){
            setChecked(user);
            singleItem.completedBy = user;
            singleItem.unCompletedBy = null;
            completeActionItem(id, collection, user);
            updateCompletedTotal(true);
        }else{
            unCompleteActionItem(id, collection, user);
            setChecked(false);
            singleItem.completedBy = null;
            singleItem.unCompletedBy = user; 
            updateCompletedTotal(false); 
        }
        changeNotes();
    }

    const changeNotes = () =>{
        if(notes){
            singleItem.actionNotes = notes;
            updateActionNotes(id, collection, notes);
        }
        handleClose();
    }

    const handleChange = (event) => {
        setNotes(event.target.value);
    }

    useEffect(() => {
        const fetchData = async () => {
            if (collection === 'scheduled' && showEntriesFor === 'websiteChat') return;

            const entry = await fetchEntry(id, collection);
            if(entry && entry.actionNotes){
                setNotes(entry.actionNotes);
            }
        }
        fetchData();
    }, [id, collection, showEntriesFor])

    return (
        <Dialog fullWidth={true} open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Action Item</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { notes ? "" : "Please leave a note." }
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="notes"
                    label="Note (optional)"
                    placeholder="This note will only be visible to other users from your office."
                    type="text"
                    value={notes}
                    onChange={handleChange}
                    fullWidth
                /> 
            </DialogContent>
            <DialogActions>
                <Link to={`${locationPath}`}>
                    <Button onClick={() => {handleClose()}} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={takeAction} color="primary">
                        {complete ? "Complete" : "Mark as Incomplete" }
                    </Button>
                    <Button onClick={changeNotes} color="primary">
                        leave note
                    </Button>
                </Link>
            </DialogActions>
        </Dialog>
    )
}

const styles = {

}

export default withStyles(styles, { "withTheme": true })(ActionItemDialog);