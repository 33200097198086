/* eslint-disable array-callback-return */
import store, {functions} from "../../store";
import axios from 'axios';
import { defaultNotifications } from './common';

export const fetchUsers = async (officeId, offices) => {
    let officeData = [];
    if(officeId !== 'all-offices'){
        const officeDoc = await store.firestore.collection("clients").doc(officeId).get();
        const officeInfo = officeDoc.data();
        const officeUsers = officeInfo.users;
        if(officeUsers){
            for(let user in officeUsers){
                let userInfo = [];
                userInfo['officeName'] = officeInfo.officeInformation.name;
                userInfo['officeId'] = officeDoc.id;
                userInfo['email'] = officeUsers[user]['email'];
                userInfo['phone'] = officeUsers[user]['phone'];
                userInfo['id'] = user;
                officeData.push(userInfo);
            }
        }
    }
    else{
        let allOfficeData = await fetchMultipleOffices(offices);
        allOfficeData.forEach(officeDoc => {
            const officeInfo = officeDoc.data();
            const officeUsers = officeInfo.users;
            if(officeUsers){
                for(let user in officeUsers){
                    let userInfo = [];
                    userInfo['officeName'] = officeInfo.officeInformation.name;
                    userInfo['officeId'] = officeDoc.id;
                    userInfo['email'] = officeUsers[user]['email'];
                    userInfo['phone'] = officeUsers[user]['phone'];
                    userInfo['id'] = user;
                    officeData.push(userInfo);
                }
            }
        })
    }
    return officeData;
}

const fetchMultipleOffices = async (offices) => {
    return Promise.all(offices.map(async office => {
            const officeDoc = await store.firestore.collection("clients").doc(office).get();
            return officeDoc;
    }));
}

export const deleteUser = async (officeId, id) => {
    const officeDoc = await store.firestore.collection("clients").doc(officeId).get()
    const officeData = officeDoc.data();
    let users = officeData.users;
    delete users[id];
    store.firestore.collection("clients").doc(officeId).update({ 'users': users }).then(res => {
        store.firestore.collection("accounts").doc(id).get().then(user => {
            user = user.data();
            let newOffices = user.offices.filter((offid) => {
                return offid !== officeId;
            });
            store.firestore.collection("accounts").doc(id).update({ 'offices': newOffices }).then(res => {
            })
            .catch(err => {
                console.error(err);
            })
        })
    }).catch((err) => {
    })
}

export const addUser = async (officeId, values) => {
    let { firstName, lastName, email, phoneNumber } = values;
    phoneNumber = values.phoneNumber.replace(/\D/g, '');
    const profile = {
        email,
        firstName,
        lastName,
        'phone': phoneNumber,
        offices: [officeId],
        notifications: defaultNotifications,
        username: email.split('@')[0]
      };
      const url = `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/createUser`;
      const reqBody = { email, profile };
      const config = {
        headers: { 'Content-Type': 'application/json' }
      };

      axios
        .post(url, reqBody, config)
        .then(user => {
        })
        .catch(err => {
          this.setState({
            submitError: true,
            errorText: err.code,
            loadingAfterSubmit: false
          });
        });
}

export const fetchUsrInformation = async (uid) => {
    const userDoc = await store.firestore.collection("accounts").doc(uid).get();
    return userDoc.data();
}

export const updateAccountInformation = async (props) => {
    const { userInformation } = props;
    const reqBody = {
        firstName: userInformation.firstName, 
        lastName: userInformation.lastName,
        email: userInformation.userEmail,
        phone: userInformation.userPhone.replace(/\D/g, '')
    }
    const updateAccount = functions()
    .httpsCallable('accounts-accountUpdate');

    return await updateAccount(reqBody)
}

export const updateAccountNotifications = async (props) => {
    const {categories, setSaving } = props;
    const reqBody = {
        notifications: categories
    };
    const updateAccount = functions().httpsCallable('accounts-accountUpdate');
    await updateAccount(reqBody);
    setSaving(false);
    return;
}
