import React from 'react';
import { compose } from 'redux';
import DateRangePicker from './DateRangePicker';
import DownloadButton from './DownloadReport/DownloadButton';
import PrintButton from './PrintReport/PrintButton';
import { withStyles } from '@material-ui/core/styles';


const ReportActionMenu = (props) => {
    const {classes, params, reportIsRange, showEntriesFor, offices, allOffices} = props;
    return(
        <div className={classes.root}>
            <PrintButton params={params} reportIsRange={reportIsRange} showEntriesFor={showEntriesFor} offices={offices} allOffices={allOffices}/>
            <DownloadButton params={params} reportIsRange={reportIsRange} showEntriesFor={showEntriesFor} offices={offices} allOffices={allOffices}/>
            <DateRangePicker params={params} reportIsRange={reportIsRange} showEntriesFor={showEntriesFor}/>
        </div>
    )
}


const styles = theme => ({
    root: {
      width: '100%',
      display: 'flex',
      paddingTop: 10,
      paddingRight: 5,
      paddingBottom: 5,
      flexDirection: 'row',
      justifyContent: "flex-end"
    },
    icon: {
        '&:hover':{
            color: theme.palette.secondary.main
        }
    }
  });

  export default compose(
    withStyles(styles, { withTheme: true })
  )(ReportActionMenu);
