import React, { Component, useState } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { DatePicker } from 'material-ui-pickers';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

class DateRangeForm extends Component {
  onSubmit(values) {
    const { 
      history,
      params, 
      singleDayVal,
    } = this.props;

    if (singleDayVal) {
      let day = moment(values.date).format('YYYY-MM-DD');
      history.push(`/${params.office}/${day}`);
    } else {
      let start = moment(values.dateStart).format('YYYY-MM-DD');
      let end = moment(values.dateEnd).format('YYYY-MM-DD');
      history.push(`/${params.office}/${start}/${end}`);
    }
  }

  renderCheckbox(field) {
    return (
      <span className={field.labelcontainerclassname}>
        <Checkbox
          className={field.checkboxclassname}
          checked={field.input.value}
          onChange={(e, change) => field.input.onChange(change)}
        />
        <Typography variant='body1' className={field.checkboxlabelclassname}>
          {field.label}
        </Typography>
      </span>
    );
  }

  renderDateField(field) {
    const { meta: { touched, error } } = field;
    const [selectedDate, handleDateChange] = useState(new Date());

    return (
      <DatePicker
        label={field.label}
        className={field.className}
        value={selectedDate}
        onChange={handleDateChange}
        format={field.formatDate}
        animateYearScrolling
        disableFuture
        margin='normal'
        error={touched && error}
        helperText={touched && error}
        variant='outlined'
        {...field.input}
      />
    );
  }

  renderSingleDay() {
    return (
      <Field
        name='date'
        component={this.renderDateField}
        label='Date'
        formatDate='MMMM D, YYYY'
        className={this.props.classes.field}
      />
    );
  }

  renderDateRange() {
    const { classes } = this.props;

    return (
      <div className={classes.dateRangeFields}>
        <Field
          name='dateStart'
          component={this.renderDateField}
          label='Start Date'
          formatDate='MMMM D, YYYY'
          className={classes.dateStartField}
        />
        <Field
          name='dateEnd'
          component={this.renderDateField}
          label='End Date'
          formatDate='MMMM D, YYYY'
          className={classes.dateEndField}
        />
      </div>
    );
  }

  renderForm() {
    const { classes, singleDayVal } = this.props;

    return (
      <div className={classes.dateRangeFields}>
        {singleDayVal ? this.renderSingleDay() : this.renderDateRange()}
        <Field
          name='singleDay'
          label='Single Day?'
          component={this.renderCheckbox}
          labelcontainerclassname={classes.checkboxLabelContainer}
          checkboxclassname={classes.checkbox}
          checkboxlabelclassname={classes.checkboxLabel}
        />
        <div className={classes.buttonContainer}>
          <Button
            size='large'
            variant='contained'
            color='primary'
            className={classes.button}
            type='submit'
          >
            Go
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { classes, handleSubmit } = this.props;

    return (
      <div className={classes.root}>
        <form
          className={classes.form}
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit(this.onSubmit.bind(this))}
        >
          {this.renderForm()}
        </form>
      </div>
    );
  }
}

const styles = theme => ({
  root: {},
  field: {
    width: '100%',
    margin: '16px auto 8px auto',
    '&:hover': {
      borderColor: '#696969',
    }
  },
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    margin: '16px 0px 22px 0px',
  },
  dateRangeFields: {
    display: 'block',
  },
  dateStartField: {
    display: 'block',
  },
  dateEndField: {
    display: 'block',
  },
  checkboxContainer: {
    display: 'flex',
    flex: 1,
    alignContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  checkboxLabelContainer: {
    display: 'flex',
    flex: '1',
    alignContent: 'space-between',
    justifyContent: 'center',
  },
  checkbox: {
    display: 'flex',
    flex: 1,
  },
  checkboxLabel: {
    display: 'flex',
    flex: 20,
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

const validate = (values) => {
  const errors = {};

  if (values.singleDay) {
    if (!values.date) {
      errors.date = 'Please select a date';
    }
  } else {
    if (!values.dateStart) {
      errors.dateStart = 'Please select a start date';
    }

    if (!values.dateEnd) {
      errors.dateEnd = 'Please select an end date';
    }

    if (values.dateStart && values.dateEnd) {
      if (values.dateStart.unix() >= values.dateEnd.unix()) {
        errors.dateStart = 'Start date must be before end date';
      }
    }
  }

  return errors;
};

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('DateRangeForm');
  let initialValues;
  props.reportIsRange
    ? initialValues = {
      date: moment(props.params.reportDateStart),
      dateStart: moment(props.params.reportDateStart),
      dateEnd: moment(props.params.reportDateEnd),
      singleDay: false,
    }
    : initialValues = {
      date: props.params.reportDate,
      dateStart: moment(props.params.reportDate).startOf('month'),
      dateEnd: moment(props.params.reportDate).endOf('month'),
      singleDay: true,
    };

  return {
    initialValues,
    singleDayVal: selector(state, 'singleDay')
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    validate,
    form: 'DateRangeForm',
    enableReinitialize : true
  }),
  withRouter,
  withStyles(styles, { withTheme: true })
)(DateRangeForm);
