import algoliasearch from 'algoliasearch';
import store from "../store";
import { OFFERINGS_CATEGORIES_INITIALIZE } from './types.js';
function compare( a, b ) {
  if ( a.category < b.category ){
    return -1;
  }
  if ( a.category > b.category ){
    return 1;
  }
  return 0;
}

export const offeringsCategoriesInitialize = () => {
  const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_BROWSE_OFFERINGS_CATEGORIES
  );
  const index = algoliaClient.initIndex(
    process.env.REACT_APP_ALGOLIA_OFFERINGS_CATEGORIES_INDEX_NAME
  );
  const browser = index.browseAll();
  let hits = [];

  return (dispatch) => {
    let offeringsCategories;

    browser.on('result', (content) => { hits = hits.concat(content.hits) });
    browser.on('end', () => {
      offeringsCategories = hits.sort(compare);
      hits.forEach((doc) => {
        let subCategories = [];
        store.firestore.get({
          collection: 'offeringsCategories',
          doc: doc.id,
          subcollections: [{
              collection: 'subcategories'
          }],
          orderBy: [['category']],
        })
        .then(querySnapshot => {
          querySnapshot.docs.forEach(subdoc => {
            let docData = subdoc.data();
            docData['id'] = subdoc.id;
            docData['parentCategory'] = doc.category;
            subCategories.push(docData);
          });
          doc['subcategories'] = subCategories;
        }).catch(error =>{
        })
      });
      dispatch({ type: OFFERINGS_CATEGORIES_INITIALIZE, payload: offeringsCategories});
    });
    browser.on('error', function onError(err) { throw err });
  };
};