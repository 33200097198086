import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export default function(ComposedComponent) {
  class Authentication extends Component {
    static contextTypes = {
      router: PropTypes.object
    };

    UNSAFE_componentWillMount() {
      if (!this.props.authenticated && this.props.authenticated.isEmpty) {
        this.props.history.push(`/login`);
      }
    }

    UNSAFE_componentWillUpdate(nextProps) {
      const { authenticated} = nextProps;

      if (authenticated.isLoaded && authenticated.isEmpty) {
        this.props.history.push(`/login`);
      }
    }

    render() {
      const { authenticated } = this.props;

      if (authenticated && !authenticated.isEmpty) {
        return <ComposedComponent {...this.props} />;
      }

      return null;
    }
  }

  function mapStateToProps(state, ownProps) {
    return { authenticated: state.firebase.auth };
  }

  return connect(mapStateToProps)(Authentication);
}
