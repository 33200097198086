/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from "lodash";
import { Typography, Divider, Grid, Button, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';
import CategoryFields from './CategoryFields'
import { updateAccountNotifications, fetchUsrInformation } from './ManageUsers';
import Loader from 'react-loader-spinner';

const NotificationPage = (props) => {
    const { classes, categories, checkboxCategories, uid } = props;
    const [ newCategories, setNewCategories ] = useState({});
    const [ originalCategories, setOriginalCategories ] = useState(null);
    const [ changed, setChanged] = useState(false);
    const [ checkboxChange, setCheckBoxChange ] = useState(false);
    const [ saving, setSaving] = useState(false);


    const updateNotifications = () => {
        setSaving(true);
        updateAccountNotifications({uid, categories: newCategories, setSaving});
        setChanged(false);
        setCheckBoxChange(false);
    }

    useEffect(()=>{
        const fetchData = async () => {
            const userData = await fetchUsrInformation(uid);
            setOriginalCategories(userData.notifications ? userData.notifications : {});
            setNewCategories(userData.notifications ? userData.notifications : {});
        }
        fetchData();
    }, [uid])


    return (
        <>
            <Paper className={classes.settings}>
                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1} className={classes.centeredTitle}>
                    <Grid item sm={10} xs={6}>
                        <Typography variant="h6">
                            Category Notifications
                        </Typography>
                    </Grid> 
                    <Grid item sm={2} xs={6} container direction="row" justify="center" alignItems="flex-end" spacing={1}>
                        <Button variant="contained" 
                                color='secondary'
                                disabled={!changed}
                                onClick={updateNotifications}
                        >
                            {saving ?
                                <Loader type="Oval"
                                        height="20"
                                        color="black"
                                        width="20"/>
                                :
                                'Save Changes'
                            }
                            
                        </Button>
                    </Grid>
                    <Grid item xs={12} >
                        <Divider />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="flex-end" spacing={1} className={classes.notificationInputs}>
                    {originalCategories ? _.map(categories, category => {
                        return (
                            <CategoryFields 
                                category={category}
                                originalCategories={originalCategories}
                                newCategories={newCategories} 
                                setNewCategories={setNewCategories}
                                setChanged={setChanged}
                                key={category.objectID}
                            />
                        )
                    }):
                    <Loader type="Oval"
                            height="25"
                            color="black"
                            width="25"/>
                    }
                </Grid>
            </Paper>
            <Paper className={classes.settings} style={{marginTop: '75px'}}>
                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1} className={classes.centeredTitle}>
                    <Grid item sm={10} xs={6}>
                        <Typography variant="h6">
                            New Patient Notifications
                        </Typography>
                    </Grid> 
                    <Grid item sm={2} xs={6} container direction="row" justify="center" alignItems="flex-end" spacing={1}>
                        <Button variant="contained" 
                                color='secondary'
                                disabled={!checkboxChange}
                                onClick={updateNotifications}
                        >
                            {saving ?
                                <Loader type="Oval"
                                        height="20"
                                        color="black"
                                        width="20"/>
                                :
                                'Save Changes'
                            }
                            
                        </Button>
                    </Grid>
                    <Grid item xs={12} >
                        <Divider />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="flex-end" spacing={1} className={classes.notificationInputs}>
                    {originalCategories ? _.map(checkboxCategories, category => {
                        return (
                            <CategoryFields 
                                category={category}
                                originalCategories={originalCategories}
                                newCategories={newCategories} 
                                setNewCategories={setNewCategories}
                                setCheckBoxChange={setCheckBoxChange}
                                key={category.objectID}
                            />
                        )
                    }):
                    <Loader type="Oval"
                            height="25"
                            color="black"
                            width="25"/>
                    }
                </Grid>
            </Paper>
        </>
    )
}

const mapStateToProps = (state) => {    
    return {
        userInfo: { name: state.firebase.auth.displayName,
                    email: state.firebase.auth.email
        },
        uid: state.firebase.auth.uid,
        categories: state.offeringsCategories.filter((category) => {return !category.old && !category.checkbox}),
        checkboxCategories: state.offeringsCategories.filter((category) => {return !category.old && category.checkbox})
    };
} 

export default compose(
    connect(mapStateToProps),
    withStyles(styles, { withTheme: true }))(NotificationPage);