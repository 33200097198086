import moment from "moment-timezone";
import _ from "lodash";
import { parsePhoneNumber } from "libphonenumber-js";
import { t } from 'typy';

export const createFileName = (params, office, allOffices, service) => {
    const start = moment(params.reportDateStart).format('MMMM D, YYYY');
    const end = moment(params.reportDateEnd).format('MMMM D, YYYY');

    if(service === "all-services"){
        service = "All Services";
    }
    else if(service === "recall"){
        service = "Recall";
    }
    else if(service === "treatment"){
        service = "Scheduled Treatment";
    }
    else if(service === "websiteChat"){
      service = "Website Chat";
  }
    else{
        service = "Answering Services";
    }

    let fileName = "";
    if(office !== 'all-offices'){
        fileName += allOffices[0].officeInformation.name + " ";
    }
    fileName += service + " ";
    fileName += `${start} to ${end}`;
    return fileName;
};

const getCollectionTextForReport = (collection, item) =>{
    if(item.appointmentTime)
    {
        collection = "New Appointment Scheduled"
    }
    else if(collection === "notScheduled"){
        collection = "Other Calls Handled";
    } else if (collection === "websiteChat") {
      collection = "Website Chats Handled";
    }
    else{
        collection = "Calls Answered";
    }
    return collection;
}

export const createCallDetails = (item, allOffices, officeID) =>{
    const office = _.filter(allOffices, o => o.id === officeID)[0];
    const officeTimezone = t(office, 'officeInformation.timezone').safeObject
    ? t(office, 'officeInformation.timezone').safeObject
    : moment.tz.guess(true);
    const createdTime = moment.unix(item.createdTime.seconds).tz(officeTimezone);
    let appointmentTime;
    let apptDate;
    let apptTime;
    if(item.appointmentTime){
        appointmentTime = moment.unix(item.appointmentTime.seconds).tz(officeTimezone);
        apptDate = appointmentTime.format('dddd MMMM D YYYY');
        apptTime = appointmentTime.format('h:mm a');
    }
    // let callNotes = "No notes needed";
    // if(item.reason && item.reason.notes !== undefined){
    //     callNotes = item.reason.notes.replace(/(\r\n|\n|\r|,)/gm,"");
    // }
    // if(item.notes !== undefined){
    //     callNotes = item.notes.replace(/(\r\n|\n|\r|,)/gm,"");
    // }
    // if(item.reasonForCall !== undefined){
    //     callNotes = item.reasonForCall.replace(/(\r\n|\n|\r|,)/gm,"");
    // }
    // if(item.neededFollowUp !== undefined){
    //     callNotes = item.neededFollowUp.replace(/(\r\n|\n|\r|,)/gm,"");
    // }
    // if(item.actionTaken !== undefined){
    //     callNotes = item.actionTaken.replace(/(\r\n|\n|\r|,)/gm,"");
    // }
    let callDetails = {
        patientName: item.patientName.replace(/,/g, ''),
        patientPhoneNumber: parsePhoneNumber(item.patientPhone, 'US').formatNational(),
        callDate: `${createdTime.format('ddd MMM D YYYY')} at ${createdTime.format('h:mm a')}`,
        collection: getCollectionTextForReport(item.collection, item),
        appointmentTime: (item.appointmentTime ? `${apptDate} at ${apptTime}` : ""),
        actionItem: (item.actionItem ? 'Yes' : 'No'),
        // callNotes,
    }
    if(item.notes) {
        callDetails.callNotes = item.notes.replace(/(\r\n|\n|\r|,)/gm,"");
    }
    if(item.reason && item.reasonForCall && !item.reason.notes) {
        callDetails.callNotes = item.reasonForCall.replace(/(\r\n|\n|\r|,)/gm,"")
    }
    if(item.reason && !item.reasonForCall && item.reason.notes) {
        callDetails.callNotes = item.reason.notes.replace(/(\r\n|\n|\r|,)/gm,"")
    }
    if(item.reason && item.actionTaken) {
        callDetails.actionTaken = item.actionTaken.replace(/(\r\n|\n|\r|,)/gm,"")
    }
    if(item.reason && item.neededFollowUp) {
        callDetails.neededFollowUp = item.neededFollowUp.replace(/(\r\n|\n|\r|,)/gm,"")
    }

    if(officeID === "all-offices" && allOffices && allOffices.length) {
        let officeOfItem = allOffices.filter((office) => office.id === item.office);
        callDetails = {
            officeName: officeOfItem[0].officeInformation.name,
            ...callDetails
        }
    }
    return callDetails;
}

export const createReportHeaders = (office) => {
    let headers = {
        patientName: 'Patient Name',
        patientPhoneNumber: 'Patient Phone Number',
        callDate: 'Date of call',
        collection: 'Collection',
        appointmentTime: 'Appointment Time',
        actionItem: 'Action item',
        callNotes: 'Call Notes',
        actionTaken: 'Action Taken',
        neededFollowUp: 'Needed Follow-Up'
    };
    if(office === 'all-offices'){
        headers = {
            officeName: 'Office Name',
            ...headers
        };
    }
    return headers;
}

const convertToCSV = (objArray) => {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== ''){
                line += ','
            } 
            line += array[i][index];
        }
        str += line + '\r';
    }
    return str;
};

export const exportCSVFile = (headers, items, fileTitle) => {
    if (headers) {
        items.unshift(headers);
    }
    console.log('items', items)
    var jsonObject = JSON.stringify(items);
    console.log('jsonobject', jsonObject)
    var csv = convertToCSV(jsonObject);
    console.log('csv', csv);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};