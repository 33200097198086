export const styles = theme => ({
  itemEntry: {
    display: 'block',
    flexWrap: 'wrap',
    width: '100%',
    margin: '0 auto',
    maxHeight: '400px',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
  },
  sectionCollapseButton: {
    display: 'block',
    flexWrap: 'wrap',
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
  },
  sectionTitle: {
    marginTop: '45px',
    textAlign: 'center',
    marginBottom: '15px',
  },
  contentContainer: {
    width: '100%',
    margin: '0px auto',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flex: 1,
    },
  },
  cell: {
    flexGrow: '1',
    margin: '0px auto',
    listStyle: 'none',
  },
  contentLeft: {
    padding: '0.8em 1.2em 0em 1.2em',
    [theme.breakpoints.up('sm')]: {
      flex: 2,
      padding: '0.8em 1.2em',
    },
  },
  urgentIcon: {
    verticalAlign: 'middle',
    marginLeft: '3px',
    '& svg': {
      fontSize: '0.875rem',
      marginRight: '0px',
      color: theme.palette.error.dark,
    }
  },
  actionIcon: {
    '& svg': {
      verticalAlign: 'middle',
      '&:hover': {
        cursor: 'pointer',
      }
    }
  },
  actionNotes: {
    '& svg': {
      verticalAlign: 'middle',
      '&:hover': {
        cursor: 'pointer',
      }
    },
    '& span': {
      marginLeft: '5px'
    }
  },
  checkedActionIcon: {
    '& svg': {
      verticalAlign: 'middle',
      color: theme.palette.secondary.main,
      '&:hover': {
        cursor: 'pointer',
      }
    },
    '& span': {
      marginLeft: '5px'
    }
  },
  officeName: {
    fontSize: '12px',
  },
  contentMiddle: {
    padding: '0em 1.2em',
    [theme.breakpoints.up('sm')]: {
      flex: 3,
      padding: '0.8em 1.2em',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  contentMiddleText: {
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      fontStyle: 'italic',
    },
  },
  contentRight: {
    padding: '0em 1.2em 0.8em 1.2em',
    [theme.breakpoints.up('sm')]: {
      flex: 3,
      padding: '0.8em 1.2em',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
});