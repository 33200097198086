import React, { Component } from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { DehazeSharp} from "@material-ui/icons";
import { isLoaded, isEmpty, firestoreConnect } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';
import ReportActionMenu from '../../components/ReportActionMenu'
import LogoutButton from '../../components/LogoutButton';
import MissingInfo from '../../components/MissingInfo';
import requireAuth from '../../components/RequireAuth';
import Report from '../../components/Report';
import ReportText from '../../components/ReportText';
import ResponsiveDrawer from '../../components/ResponsiveDrawer';
import Voicemails from '../../components/Voicemails'
import Settings from '../../components/Settings/index'
import Loader from "../../components/common/Loader";
import { isEmployee } from '../../actions';
import { validatedParams } from '../../services/validation';
import logo from "../../services/images/logo.png";
import { styles } from './styles';

class Home extends Component {
  state = {
    showEntriesFor: "answering",
    mobileOpen: false,
    pageTitle: "Reports",
    initialRender: true,
    initialValueForDefaultService: null,
  };

  componentDidMount() {
    if (this.detectEntryInURL(window.location.href)) {
      const entryType = this.detectEntryType(window.location.href);
      this.changeService(entryType);
      this.changeUrlAfterUrgentRedirect();
    };
    const { auth, isEmployee } = this.props;
    isEmployee(auth.uid);
  }

  componentDidUpdate(prevProps){
    if( this.props.params.office === 'all-offices' 
    && this.props.allOffices.length > 0 
    && this.state.initialRender ){
      this.defaultToAvailableServiceInAllOffices(this.props.allOffices)
    }

    if( this.props.params.office !== 'all-offices' 
    && this.getCurrOfficeObj(this.props.params.office) 
    && this.state.initialRender
    ){
      if (this.detectEntryType(window.location.href)){
        this.detectServiceFromEntry(window.location.href)
      } else {
        this.detectServiceAsDefault(this.getCurrOfficeObj(this.props.params.office))
      }
    }

    if( prevProps.params.office !== this.props.params.office 
      && this.getCurrOfficeObj(this.props.params.office)){
        if(this.detectEntryType(window.location.href)){
          this.detectServiceFromEntry(window.location.href)
        } else {
          this.detectServiceAsDefault(this.getCurrOfficeObj(this.props.params.office))
        }
    }

    if(prevProps.params.office !== this.props.params.office 
      && this.props.allOffices.length > 0 
      && this.props.params.office === 'all-offices'){
      this.defaultToAvailableServiceInAllOffices(this.props.allOffices)
    }

    if (prevProps.notScheduled !== this.props.notScheduled
      && this.detectEntryType(window.location.href)) {
        this.detectServiceFromEntry(window.location.href)
      }
  }

  changeUrlAfterUrgentRedirect(){
    const regexAmp = /&/;
    const newUrlParams = this.props.history.location.pathname.replace(regexAmp, '') + this.props.history.location.search;
    this.props.history.replace(newUrlParams);
  }

  getCurrOfficeObj(officeParam){
    const currentOffice = this.props.allOffices.filter((office) => office.id === officeParam)[0];
    if(currentOffice){
      return currentOffice;
    } else {
      return false;
    }
  }

  detectEntryInURL(url) {
    const regexEntry = /&\?entry/
    return regexEntry.test(url);
  }

  detectEntryType(url) {
    const regexCollection = /(callsAnswered|scheduledTreatment|scheduled|notScheduled)/;
    if (!url.match(regexCollection)) return false;
    let entryType = url.match(regexCollection)[0];
    if (entryType === 'scheduled' | entryType === 'notScheduled'){
      entryType = 'recall';
    } else if (entryType === 'callsAnswered') {
      entryType = 'answering';
    } else if (entryType === 'scheduledTreatment') {
      entryType = 'treatment';
    }
    
    return entryType;
  }

  detectServiceFromEntry(url){
    let defaultService = 'answering';
    let initialIndexForService = 0;
    const regexCollection = /(callsAnswered|scheduledTreatment|scheduled|notScheduled)/;
    defaultService = url.match(regexCollection)[0];
    if (defaultService === 'scheduled'){
      defaultService = 'recall';
      initialIndexForService = 1;
    } else if (defaultService === 'callsAnswered') {
      defaultService = 'answering';
      initialIndexForService = 0;
    } else if (defaultService === 'scheduledTreatment') {
      defaultService = 'treatment';
      initialIndexForService = 2;
    } else if (defaultService === 'notScheduled') {
      defaultService= 'recall';
      initialIndexForService = 1;
      if (this.props.notScheduled && this.props.history.location.search) {
        const regexEntryId = this.props.history.location.search.split('%22')[3];
        defaultService = this.props.notScheduled.filter((entry) => entry.id === regexEntryId)[0].entryType;
        if (!defaultService) return;
        initialIndexForService = defaultService === 'treatment' ? 2 : 1;
      }
    }
  
    this.setState({initialValueForDefaultService: initialIndexForService, initialRender: false, showEntriesFor: defaultService})
}

  detectServiceAsDefault(office){
    let defaultServiceExist = false;
    let defaultService = 'answering';
    let initialIndexForService = 0;
      if (office.offerings.answering) {
        defaultServiceExist = true;
      };
      if (!defaultServiceExist && defaultService === 'answering') {
        if (office.offerings.recall) {
          defaultServiceExist = true;
          defaultService = 'recall';
          initialIndexForService = 1;
        };
        if(!defaultServiceExist && defaultService === 'recall') {
          defaultService = 'treatment';
          initialIndexForService = 2;
        };
    };

    this.setState({initialValueForDefaultService: initialIndexForService, initialRender: false, showEntriesFor: defaultService})
  }

  defaultToAvailableServiceInAllOffices(allOffices){
    let defaultServiceExist = false;
    let defaultService = 'answering';
    let initialIndexForService;
    const officeKeys = Object.keys(allOffices);
    officeKeys.forEach((office) => {
      if (allOffices[office].offerings.answering) { 
        defaultServiceExist = true;
      }
    })
    if (!defaultServiceExist && defaultService === 'answering'){
      defaultService = 'recall';
      officeKeys.forEach((office) => {
        if (allOffices[office].offerings.recall) {
          defaultServiceExist = true;
        }
      })
    }
    if (!defaultServiceExist && defaultService === 'recall') {
      defaultService = 'treatment'
    }
    if (defaultService === 'answering') {
      initialIndexForService = 0;
    } else if (defaultService === 'recall') {
      initialIndexForService = 1;
    } else {
      initialIndexForService = 2;
    }
    
    this.setState({initialValueForDefaultService: initialIndexForService, initialRender: false, showEntriesFor: defaultService})
  }

  changeService = (value) => {
    this.setState({showEntriesFor: value})
    if(value === "settings"){
      this.setState({pageTitle: "Settings"})
    }
    else if(value === "voicemails"){
      this.setState({pageTitle: "Voicemails"})
    }
    else{
      this.setState({pageTitle: "Reports"})
    }
  };

  renderReport() {
    const {
      isEmp,
      params,
      reportIsRange,
      profile,
      allOffices
    } = this.props;
    const { showEntriesFor } = this.state;
    const profileReady = isLoaded(profile) && !isEmpty(profile);
    const profileHasOffice = profileReady && ((profile.offices && profile.offices.includes(params.office)) || params.office === 'all-offices');
    const notEmp = !isEmp && isEmp !== null;
    const allOfficesLoaded = profileReady && ((!_.isEmpty(allOffices) && allOffices.length === (profile.offices && profile.offices.length)));
    if(showEntriesFor !== 'voicemails' && showEntriesFor !== 'settings')
    {
      if(isEmpty(profile) || (notEmp && !profileHasOffice && allOfficesLoaded)) {
        return <ReportText params={params} authorizedUser={false} profile={profile} />;
      } else if(isEmp || (profileHasOffice && allOfficesLoaded)) {
        return <Report isEmp={isEmp}  params={params} reportIsRange={reportIsRange} showEntriesFor={showEntriesFor} offices={profile.offices} allOffices={allOffices}/>;
      } else {
        return <Loader />;
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  renderMainSection() {
    const { classes, params, profile, reportIsRange, allOffices} = this.props;
    const { showEntriesFor } = this.state;
    if(!isLoaded(profile)) {
      return <Loader />;
    }

    return (
      <main className={classes.accountInfo}>
        <div className={`Home ${classes.root}`}>
          { showEntriesFor === 'settings' ?
            <Settings params={params} offices={profile.offices}/>
            :
            <Paper className={classes.report}>
            {(showEntriesFor === 'voicemails') ?
                <Voicemails params={params} offices={profile.offices}/>
              :
              <div>
                <ReportActionMenu params={params} reportIsRange={reportIsRange} showEntriesFor={showEntriesFor} offices={profile.offices} allOffices={allOffices}/>
                <div className={classes.header}>
                  <img src={logo} alt="Logo" className={classes.logo}/>
                </div>
                  {this.renderReport()}
              </div>
              }
            </Paper>
          }
        </div>
      </main>
    )
  }

  renderStatistics() {
    const { paramsValid, classes, canSeeVoicemails, history, isEmp, profile, params } = this.props;
    const { showEntriesFor, mobileOpen } = this.state;
    if(!paramsValid) {
      return <MissingInfo />;
    }

    return [
      <ResponsiveDrawer
        mobileOpen={mobileOpen}
        showEntriesFor={showEntriesFor}
        handleDrawerToggle={this.handleDrawerToggle}
        changeService={this.changeService}
        key="responsive-drawer"
        canSeeVoicemails={canSeeVoicemails}
        params={params}
        profile={profile}
        isEmp={isEmp}
        history={history}
        initialValue={this.state.initialValueForDefaultService}
        office={params.office}
      />,
      <main className={classes.wrapper} key="reports-container">
        {this.renderMainSection()}
      </main>
    ];
  }

  render() {
    const { classes, paramsValid } = this.props;
    const { pageTitle } = this.state;
    return (
      <div className={classes.main}>
        <div className={classes.logoutbtn + (!paramsValid ? " full-width" : "")}>
          {
            paramsValid ?
              <div className={classes.hamburger}>
                <button type="button" className={classes.menubtn} onClick={this.handleDrawerToggle}>
                  <DehazeSharp />
                </button>
              </div> : ""
          }
          <LogoutButton pageTitle={pageTitle}/>
        </div>
        {this.renderStatistics()}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  let canSeeVoicemails = false;
  if(state.officeSubscription.officeSubscriptionDetails){
    for(const office in state.officeSubscription.officeSubscriptionDetails) {
      if(state.officeSubscription.officeSubscriptionDetails[office].answering.current){
        canSeeVoicemails = true;
      }
    }
  }
  const profile = state.firebase.profile;
  let paramsInfo = validatedParams(props, profile && profile.offices);
  let allOffices = [];
  let isEmp = state.isEmp;
  let params = paramsInfo.value;
  let notScheduled;

  if (isEmp) {
    if(isLoaded(state.firestore.ordered[params.office]) && !isEmpty(state.firestore.ordered[params.office])) {
      allOffices.push(state.firestore.ordered[params.office][0]);
    }
  }
  else if(isLoaded(profile) && !isEmpty(profile)) {
    profile.offices.forEach((item) => {
      if(isLoaded(state.firestore.ordered[item]) && !isEmpty(state.firestore.ordered[item])) {
        allOffices.push(state.firestore.ordered[item][0]);
      }
    });
  }
  
  if (state.getItems && paramsInfo.value.office){
    const currentOffice = paramsInfo.value.office;
    notScheduled = state.getItems[currentOffice + 'notScheduledItems']
  }

  return {
    "auth": state.firebase.auth,
    "callsAnswered": _.filter(state.callsAnswered, (o) => !o.mistake),
    "isEmp": state.isEmp,
    "notScheduleds": _.filter(state.notScheduleds, (o) => !o.mistake),
    "params": paramsInfo.value,
    "paramsValid": paramsInfo.valid,
    "profile": profile,
    "reportIsRange": paramsInfo.isRange,
    "scheduleds": _.filter(state.scheduleds, (o) => !o.mistake),
    allOffices: allOffices,
    canSeeVoicemails,
    location: props.location,
    uid: state.firebase.auth.uid,
    notScheduled: notScheduled
  };
};

export default compose(
  connect(mapStateToProps, { isEmployee }),
  firestoreConnect(props => {
    const { profile, isEmp, match } = props;
    const offices = profile.offices;
    const queries = [];
    const office = match.params.office;

    if (isEmp && office) {
      queries.push({
        collection: 'clients',
        doc: office,
        storeAs: office,
      });
    } else if(isLoaded(profile) && !isEmpty(profile)) {
      offices.forEach(item => {
        queries.push({
          collection: 'clients',
          doc: item,
          storeAs: item,
        });
      });
    }
    return queries;
  }),
  withStyles(styles, { "withTheme": true }),
)(requireAuth(Home));
