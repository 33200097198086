export const styles = theme => ({
    addRow: {
        minHeight: 48,
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: '8px 24px 8px 24px',
        fontSize: theme.spacing(1.5)
    },
    body: {
        maxHeight: '448px',
        overflowY: 'auto'
    },
    cells: {
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    buttonRow: {
        width: '100%',
        marginBottom: 0,
        height: 48,
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        padding: '8px 24px 8px 24px',
        fontSize: theme.spacing(1.5)
    },
    deleteButton: {
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    header: {
      display: 'flex',
      paddingTop: 25,
      paddingLeft: 45,
      paddingBottom: 0,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 15
      }
    },
    tableHeader: {
        padding: '8px 24px 8px 24px',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        height: 50,
        marginBottom: .25,
        fontSize: theme.spacing(1.5)
    },
    inputs: {
        paddingLeft: 50,
        paddingRight: 50,
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    notificationInputs: {
        paddingLeft: 50,
        paddingRight: 50,
        width: '95%',
        paddingTop: 25,
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            width: '100%'
        },
        maxHeight: '400px',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    notificationSwitchs: {
        marginLeft: '50px',
    },
    check: {
        color: theme.palette.success.main
    },
    doesntHave: {
        color: theme.palette.secondary.main
    },
    addButton: {
        color: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            marginRight: 20
        }
    },
    centeredTitle: {
        paddingLeft: 50,
        paddingRight: 50,
        [theme.breakpoints.down('xs')]: {
            padding: 0
        },
        width: '100%'
    },
    userForm: {
        paddingLeft: 50,
        paddingRight: 50,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20,
            padding: 0
        }
    },
    tableTitle: {
        paddingBottom: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 15
        }
    },
    nonEssentialColumn: {
        [theme.breakpoints.down('xs')]: {
          display: 'none !important'
        }
      }, 
    tabBar: {
        boxShadow: 'none',
        position: 'fixed',
        top: '114px',
        marginLeft: '-85px',
        [theme.breakpoints.down('sm')]: {
            top: '70px'
        },
        [theme.breakpoints.down('xs')]: {
          top: '70px',
          marginLeft: -10
        },
        '& button': {
            minWidth: 'unset',
            minHeight: 24,
            marginRight: 24,
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
            '& > span > span': {
              paddingLeft: 2,
              paddingRight: 2,
              paddingBottom: 8,
              paddingTop: 8,
              '& > span': {
                textTransform: 'none'
              }
            }
          }
    },
    headerTab: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px'
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: '#898989'
        }
    },
    indicator: {
        backgroundColor: theme.palette.secondary.main,
        borderTopRightRadius: 2,
        borderTopLeftRadius: 2
    },
    tabsRoot: {
        minHeight: 'unset',
    },
    dialogHeader: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white'
    },
    textField: {
        width: theme.textField.width,
        margin: theme.textField.margin,
        '&:hover': {
          borderColor: theme.palette.text.primary
        }
      },
    row: {
        display: 'flex',
        '& > *': {
            flex: 1,
            marginLeft: 6,
            marginRight: 6,
            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
            }
        },
        '& > *:first-child': {
            marginLeft: 0
        },
        '& > *:last-child': {
            marginRight: 0
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    userPageButton: {
        width: theme.textField.width,
        margin: theme.textField.margin,
        marginTop: '20px'
    },
    error: {
        color: theme.palette.error.dark
    },
    userTableBody: {
        maxHeight: '250px',
        overflowY: 'auto',
        overflowX: 'hidden' 
    },
    settings: {
        marginTop: '175px',
        padding: 50,
        [theme.breakpoints.up('lg')]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
  });