import { storage, db } from '../store';
import moment from "moment-timezone";
import _ from 'lodash';

const createData = (name, date, file, number) => {
    return { name, date, file, number};
}

export const fetchAllOfficeVoicemails = async (offices, officeDoc) => {
    return Promise.all(offices.map(async officeId => {
        if(officeDoc[officeId][0].five9.skills.answering){
          const returnedVoicemails = await getVoicemails(officeDoc[officeId][0].officeInformation.name, officeDoc[officeId][0].five9.skills.answering);
          return returnedVoicemails;
        }
    }));
}

export const getVoicemails = async (officeName, skillId, setVoicemails) =>{
    let recordingsStorageRef = storage.ref();
    let path = "home/fivenine/voicemails"
    const voicemails = await getVoicemailsForOffice(path, officeName, recordingsStorageRef, skillId, setVoicemails);
    return new Promise((resolve, reject) => {
        resolve(voicemails);
    })
}

const getVoicemailsForOffice = async (path, officeName, recordingsStorageRef, skillId) => {
    const first = await recordingsStorageRef.child(path).listAll();
    const folderPaths  = await first.prefixes.map((folderRef) => { 
        let path = folderRef.location.path_+"/"+skillId;
        return path
    });

    const returnPathRef = path => {
        return new Promise((resolve, reject) =>{
            resolve(recordingsStorageRef.child(path).listAll())
        })
    }

    const getItems = async item => {
        return returnPathRef(item)
    }

    const resolvePaths = async () => {
        return Promise.all(folderPaths.map(path => getItems(path)))
    }

    const resolvedPaths = await resolvePaths();
    
    const voicemailPaths = await resolvedPaths.map((resolvedPath) => {
        return resolvedPath;
    });

    const returnUrl = path => {
        return new Promise((resolve, reject) => {
            resolve(path.getDownloadURL());
        })
    }

    const getUrls = async urlPath => {
        return returnUrl (urlPath);
    }

    const resolveUrls = async () => {
        let paths = await voicemailPaths.map(ref =>{
            return ref.items;
        })
        paths = _.flatten(paths);
        return Promise.all(paths.map(path => getUrls(path)))
    }

    const resolvedUrls = await resolveUrls();
    return new Promise((resolve, reject) => {
        let voicemails = resolvedUrls.map((url) => {
            let phoneNumber = url.replace(/.*\/|\.mp3|-.*|.*F/g,'');
            phoneNumber = `(${phoneNumber.substring(0,3)})-${phoneNumber.substring(3,6)}-${phoneNumber.substring(6,10)}`;
            let date = url.match(/([0-9]+_[0-9]+_[0-9]+)/g);
            if(date[0].length === 10){
                date = `${date[0].substring(6,10)}-${date[0].substring(0,2)}-${date[0].substring(3,5)}`;
                date = moment(date).unix();
            } else if(date[0].length === 8){
                date = `${date[0].substring(4,8)}-0${date[0].substring(0,1)}-0${date[0].substring(2,3)}`;
                date = moment(date).unix();
            } else {
                if(date[0].substring(1,2) === "_"){
                    date = `${date[0].substring(5,9)}-0${date[0].substring(0,1)}-${date[0].substring(2,4)}`;
                    date = moment(date).unix();
                } else{
                    date = `${date[0].substring(5,9)}-${date[0].substring(0,2)}-0${date[0].substring(3,4)}`;
                    date = moment(date).unix(); 
                }
            }
            return createData(officeName, date, url, phoneNumber)
        })
        resolve(voicemails);
    })
}

export const setlistened = id => {
    const callId = id.replace(/(%2F)/g, '/').replace(/.*\/|\.mp3.*/g, '').replace(/.*-/g, '');
    db.collection('voicemails').doc(callId).set({listened: true});
}

export const getlistened = async id => {
    const callId = id.replace(/(%2F)/g, '/').replace(/.*\/|\.mp3.*/g, '').replace(/.*-/g, '');
    const doc = await db.collection('voicemails').doc(callId).get();
    if(doc.data()){
        return doc.data().listened;
    }
    else{
        return false;
    }
}

export const deleteVoicemail = id => {
    const date = id.match(/([0-9]+_[0-9]+_[0-9]+)/g);
    let skillId = id.match(/%2F[0-9]+%2F/g);
    skillId = skillId[0].replace(/%2F/g, '');
    const fileName = id.replace(/.*F|\?.*/g, '')
    const filePath = `home/fivenine/voicemails/${date}/${skillId}/${fileName}`;
    let voicemailsStorageRef = storage.ref();
    let voicemailRef = voicemailsStorageRef.child(filePath);
    voicemailRef.delete().then(res => {console.log(res)}).catch((err) => {console.error(err)})
}