import React, { Component, useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Loader from "./common/Loader";
import { withStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Collapse from "@material-ui/core/Collapse";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountInfo from "./AccountInfo";
import {
  FormatListBulletedSharp,
  AccessTimeSharp,
  PhoneCallbackSharp,
  //SupervisorAccountSharp,
  ExpandLessSharp,
  ExpandMoreSharp,
  VoicemailSharp,
  Chat,
} from "@material-ui/icons";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import logo from "../services/images/logo.png";

const drawerWidth = 240;

const ITEMS = [
  {
    name: "Reports",
    key: "reports",
    childLinks: [
      // {"name": "All Services", "key": "all-services"},
      { name: "Answering Services", key: "answering" },
      { name: "Recall", key: "recall" },
      // {"name": "Website Chat", "key": "websiteChat"},
      { name: "Scheduled Treatment", key: "treatment" },
    ],
  },
  {
    name: "Voicemails",
    key: "voicemails",
    childLinks: [],
  },
  {
    name: "Settings",
    key: "settings",
    childLinks: [],
  },
];

const ICONS = {
  reports: FormatListBulletedSharp,
  // "all-services": SupervisorAccountSharp,
  recall: AccessTimeSharp,
  answering: PhoneCallbackSharp,
  voicemails: VoicemailSharp,
  websiteChat: Chat,
  treatment: EventAvailableIcon,
  settings: SettingsIcon,
};

const DrawerContent = ({
  classes,
  isDropDownOpen,
  handleDrawerToggle,
  changeService,
  toggleTab,
  canSeeVoicemails,
  history,
  isEmp,
  profile,
  params,
  initialValue,
  office
}) => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [currentOffice, setCurrentOffice] = useState(null);

  useEffect(() => {
    if (initialValue || initialValue === 0 || office !== currentOffice) {
      setSelectedIndex(initialValue);
      setCurrentOffice(office)
    }
  }, [initialValue, office, currentOffice])

  const onChangeService = (value, index, isReport) => {
    changeService(value);
    isReport ? setSelectedIndex(index) : setSelectedIndex(null);
    if (handleDrawerToggle) {
      handleDrawerToggle();
    }
  };
  
  return (
    <div>
      <div className={classes.sidebarlogo}>
        <img src={logo} alt="Logo" className={classes.insidelogo} />
      </div>
      <Divider />
      <div className={classes.sidebarlogo}>
        {profile && profile.offices ? (
          <AccountInfo
            params={params}
            offices={profile.offices}
            history={history}
            isEmp={isEmp}
          />
        ) : (
          <Loader />
        )}
      </div>
      <Divider />
      <div>
        <List className={classes.officeList}>
          {ITEMS.map((item, index) => {
            const Icon = ICONS[item.key];
            if (item.key === "voicemails" && !canSeeVoicemails) {
              return null;
            } else {
              return (
                <div key={index}>
                  <ListItem
                    button
                    onClick={() =>
                      item.key === "reports"
                        ? toggleTab()
                        : onChangeService(item.key, index, false)
                    }
                  >
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                    {item.key === "reports" ? (
                      isDropDownOpen ? (
                        <ExpandLessSharp />
                      ) : (
                        <ExpandMoreSharp />
                      )
                    ) : null}
                  </ListItem>
                  <Collapse in={isDropDownOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.childLinks
                        ? item.childLinks.map((link, linkIndex) => {
                            const LinkIcon = ICONS[link.key];
                            return (
                              <ListItem
                                button
                                key={linkIndex}
                                selected={selectedIndex === linkIndex}
                                onClick={() => onChangeService(link.key, linkIndex, true)}
                                className={classes.nested}
                              >
                                <ListItemIcon>
                                  <LinkIcon />
                                </ListItemIcon>
                                <ListItemText
                                  className={classes.listItemText}
                                  primary={link.name}
                                />
                              </ListItem>
                            );
                          })
                        : ""}
                    </List>
                  </Collapse>
                </div>
              );
            }
          })}
        </List>
      </div>
    </div>
  );
};

class ResponsiveDrawer extends Component {
  state = {
    isDropDownOpen: true,
  };

  toggleTab = () => {
    this.setState((prev) => ({ isDropDownOpen: !prev.isDropDownOpen }));
  };

  render() {
    const {
      container,
      classes,
      mobileOpen,
      handleDrawerToggle,
      changeService,
      canSeeVoicemails,
      history,
      isEmp,
      profile,
      params,
      initialValue,
      office
    } = this.props;

    const { isDropDownOpen } = this.state;
    return (
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <SwipeableDrawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onOpen={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            <DrawerContent
              classes={classes}
              handleDrawerToggle={handleDrawerToggle}
              isDropDownOpen={isDropDownOpen}
              changeService={changeService}
              toggleTab={this.toggleTab}
              canSeeVoicemails={canSeeVoicemails}
              params={params}
              profile={profile}
              isEmp={isEmp}
              history={history}
              initialValue={initialValue}
              office={office}
            />
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant="permanent"
            open
          >
            <DrawerContent
              classes={classes}
              isDropDownOpen={isDropDownOpen}
              changeService={changeService}
              toggleTab={this.toggleTab}
              canSeeVoicemails={canSeeVoicemails}
              params={params}
              profile={profile}
              isEmp={isEmp}
              history={history}
              initialValue={initialValue}
              office={office}
            />
          </Drawer>
        </Hidden>
      </nav>
    );
  }
}

const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  insidelogo: {
    width: "80%",
  },
  sidebarlogo: {
    display: "flex",
    minHeight: "64px",
    alignItems: "center",
    justifyContent: "center",
  },
  officeList: {
    paddingTop: 0,
  },
  listItem: {
    paddingLeft: theme.spacing(5),
    "&.active": {
      backgroundColor: "#E5E8E8",
    },
    "&.active span": {
      fontWeight: "bold",
    },
    "& span": {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  nested: {
    paddingLeft: theme.spacing(5),
    "& svg": {
      fontSize: theme.typography.pxToRem(18),
    },
    "& span": {
      fontSize: theme.typography.pxToRem(15),
    },
  },
});

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);
