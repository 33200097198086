import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, AppBar, Tab } from '@material-ui/core';
import UserPage from './UserPage'
import NotificationPage from './NotificationPage'
import { styles } from './styles'

const Settings = (props) => {
    const { classes, offices, params } = props;
    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
        <>
            <div className={classes.header}>
                <AppBar position="static" className={classes.tabBar}>
                    <Tabs variant='scrollable' scrollButtons='off' value={value} onChange={handleTabChange} classes={{ root: classes.tabsRoot, indicator: classes.indicator }}>
                        <Tab label="User Info" className={classes.headerTab} disableRipple={true} />
                        <Tab label="Notifications Settings" className={classes.headerTab} disableRipple={true}/>
                       
                    </Tabs>
                </AppBar>
            </div>
            {value === 0 ? 
                <UserPage /> : value === 1 ? <NotificationPage offices={offices} params={params} /> : null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        uid: state.firebase.auth.uid
    };
} 

export default compose(connect(mapStateToProps), withStyles(styles, { withTheme: true }))(Settings);