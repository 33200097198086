import { LOADING_DATA } from '../actions/types';

const initialState = {
  'summary': true,
  'actionItems': true,
  'callsAnswered': true,
  'notScheduled': true,
  'scheduled': true,
  'websiteChat': true,
};

export default (state=initialState, action) => {
  switch (action.type) {
    case LOADING_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
