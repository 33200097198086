import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';

export const remail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const rphone = /^\d{10}$/;

export const validatedParams = (props, offices) => {
  let result = {
    valid: false,
    isRange: false,
    value: null,
  };

  if (_.isUndefined(props) || _.isUndefined(props.match)) {
    return result;
  }

  let params = !_.isEmpty(props.match.params) ? props.match.params : qs.parse(props.location.search.slice(1));

  if (offices && offices.length > 1 && !params.office) {
    let startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    let currentDate = moment().format('YYYY-MM-DD');
    return {
      valid: true,
      isRange: true,
      value: {
        office: 'all-offices',
        reportDateStart: startOfMonth,
        reportDateEnd: currentDate
      }
    }
  }

  if (offices && offices.length === 1 && !params.office) {
    let startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    let currentDate = moment().format('YYYY-MM-DD');
    return {
      valid: true,
      isRange: true,
      value: {
        office: offices[0],
        reportDateStart: startOfMonth,
        reportDateEnd: currentDate
      }
    }
  }

  if (!params.reportDate && !(params.reportDateStart && params.reportDateEnd)) {
    let startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    let currentDate = moment().format('YYYY-MM-DD');
    return {
      valid: true,
      isRange: true,
      value: {
        office: params.office,
        reportDateStart: startOfMonth,
        reportDateEnd: currentDate
      }
    };
  }

  if (params.reportDate) {
    return {
      valid: true,
      isRange: false,
      value: { office: params.office, reportDate: params.reportDate }
    };
  }

  if (params.reportDateStart && params.reportDateEnd) {
    return {
      valid: true,
      isRange: true,
      value: {
        office: params.office,
        reportDateStart: params.reportDateStart,
        reportDateEnd: params.reportDateEnd
      }
    };
  }

  return result;
};
