import React, {useState} from 'react';
import { compose } from 'redux';
import _ from "lodash";
import { Checkbox, Switch, Grid, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles'
import SubCategoryFields from './SubCategoryFields'

const CategoryFields = (props) => {
    const { category, classes, newCategories, setNewCategories, setChanged, setCheckBoxChange, originalCategories } = props;
    const checkIfOrig = () => {
        if(originalCategories[category.objectID]){
            return true;
        }
        else{
            return false;
        }
    }
    const [categoryChecked, setCategoryChecked] = useState(checkIfOrig());
    const [receiveTexts, setReceiveTexts] = useState(originalCategories[category.objectID] ? originalCategories[category.objectID].text : false);
    const [receiveEmails, setReceiveEmails] = useState(originalCategories[category.objectID] ? originalCategories[category.objectID].email : true);

    const handleChecked = () => {
        if(!categoryChecked){
            let tempCategories = {};
            if(category.subcategories){
                _.map(category.subcategories, subCategory => {
                    newCategories[subCategory.id] = {
                        category: subCategory.category, 
                        parentCategory: subCategory.parentCategory, 
                        text: false, 
                        email: true
                    };
                })
            }
            newCategories[category.objectID] = {category: category.category, email: (!category.subcategories.length > 0), text: false};
            tempCategories = newCategories;
            setNewCategories(tempCategories);
        }
        else{
            if(category.subcategories){
                _.map(category.subcategories, subCategory => {
                    delete newCategories[subCategory.id];
                });
            }
            resetCategory();
        }
        setCategoryChecked(!categoryChecked);
        detectNotificationsSection();
    }

    const detectNotificationsSection = () => {
      if (!setChanged) {
        setCheckBoxChange(true);
      } else {
        setChanged(true);
      }
    }

    const handleTextChange = () => {
        newCategories[category.objectID].text = !receiveTexts;
        if(!newCategories[category.objectID].text && !newCategories[category.objectID].email){
            resetCategory();
        }
        else{
            setReceiveTexts(!receiveTexts);
        }
        detectNotificationsSection();
    }

    const handleEmailChange = () => {
        newCategories[category.objectID].email = !receiveEmails;
        if(!newCategories[category.objectID].text && !newCategories[category.objectID].email){
            resetCategory();
        }
        else{
            setReceiveEmails(!receiveEmails);
        }
        detectNotificationsSection();
    }

    const resetCategory = () => {
        let tempCategories = {};
        delete newCategories[category.objectID];
        tempCategories = newCategories;  
        setNewCategories(tempCategories);
        setCategoryChecked(false);
        setReceiveTexts(false);
        setReceiveEmails(true);
    }

    return (
        <Grid item xs={12} key={category.category}>
            <FormControlLabel control={ <Checkbox onChange={()=>{handleChecked(categoryChecked)}} checked={categoryChecked} color="secondary" /> } label={category.category} />
            { categoryChecked ? 
                <Grid item xs={12} className={classes.notificationSwitchs}>
                    { (category.subcategories && category.subcategories.length > 0) ? 
                        _.map(category.subcategories, subcategory => {
                            return(
                                <SubCategoryFields 
                                  originalCategories={originalCategories} 
                                  key={subcategory.id} 
                                  subcategory={subcategory} 
                                  setCategoryChecked={setCategoryChecked} 
                                  newCategories={newCategories} 
                                  setNewCategories={setNewCategories} 
                                  detectNotificationsSection={detectNotificationsSection}
                                />
                            )
                        })
                        :
                        <>
                            <FormControlLabel control={ <Switch size="small" onChange={handleEmailChange} checked={receiveEmails} color="secondary" /> } label="Receive Emails" />
                            <FormControlLabel control={ <Switch size="small" onChange={handleTextChange} checked={receiveTexts} color="secondary" /> } label="Receive Texts" /> 
                        </>                                   
                    }
                </Grid>
                : null
            }
        </Grid>
    )
}

export default compose(withStyles(styles, { withTheme: true }))(CategoryFields);