import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import TableContainer from '@material-ui/core/TableContainer';
import { withStyles } from '@material-ui/core/styles';
import VoicemailTable from './VoicemailTable'

const Voicemails = (props) => {
    const { classes, officeDoc, params, offices } = props;
    return(
        <div className={classes.root}>
            <TableContainer className={classes.container}>
              <VoicemailTable officeDoc={officeDoc} params={params} offices={offices}/>
            </TableContainer>
        </div>
    )
}


const styles = theme => ({
    root: {
      display: 'flex',
      paddingTop: 10,
      paddingRight: 5,
      marginTop: 5
    },
    container: {
      maxHeight: 440,
    },
  });
  const mapStateToProps = (state) => {
      return {
        officeDoc: state.firestore.ordered
      };
    }  
  export default compose(
    connect(mapStateToProps),
    withStyles(styles, { withTheme: true })
  )(Voicemails);
