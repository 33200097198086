import React, { PureComponent } from 'react';
import CountUp from 'react-countup';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import itemsCollection from "./common/ItemsCollection";
import _ from 'lodash';

import Loader from "./common/Loader";

class Summary extends PureComponent {

  renderSchStats(count) {
    return (
      <div>
        <Typography variant='h3' color='secondary'>
          <CountUp duration={2} end={count} separator="," />
        </Typography>
        <Typography variant='caption'>Appts. Scheduled</Typography>
      </div>
    );
  }

  renderEstRev(count) {
    const { officeDoc } = this.props;

    let apptValue = 0;
    let estShowRate = 0;

    officeDoc && officeDoc.forEach((doc) => {
      if(doc) {
        const { officeNotes } = doc;
        apptValue += officeNotes && officeNotes.apptValue ? officeNotes.apptValue : 0;
        estShowRate += officeNotes && officeNotes.estShowRate ? officeNotes.estShowRate : 0;
      }
    });

    apptValue = apptValue || 199;
    estShowRate = estShowRate || 0.75;

    return (
      <div>
        <Typography variant='h3' color='secondary'>
          <CountUp
            duration={1}
            end={count * apptValue * estShowRate}
            separator=","
            decimals={2}
            prefix="$"
            />
        </Typography>
        <Typography variant='caption'>Est. Revenue Generated</Typography>
      </div>
    );
  }

  renderCallsHandled(count) {
    const { showEntriesFor } = this.props;
    return (
      <div>
        <Typography variant='h3' color='secondary'>
          {count}
          {/* <CountUp duration={2} end={count} separator="," /> */}
        </Typography>
        <Typography variant='caption'>
          {showEntriesFor === 'websiteChat' ? 'Website Chats Handled' : 'Phone Calls Handled'}
        </Typography>
      </div>
    );
  }

  renderMainBody() {
    const {
      scheduledItems, 
      scheduledTreatmentItems, 
      notScheduledItems,
      actionItems, 
      allItems, 
      params, 
      //recallUpdated, 
      showEntriesFor, 
      //recallsHandledCount, 
      classes, 
      isLoadingSummary,
      totalAnsweringServicesCallsHandled
    } = this.props;
    let {callsHandledCount, count} = this.props;
    let currentOffice = params.office;
    let collection;
    if (showEntriesFor === 'answering') {
      collection = 'callsAnswered'
    } else if (showEntriesFor === 'treatment') {
      collection = 'treatment';
    } else {
      collection = 'scheduled';
    }
    const handledItems = [];
    if (currentOffice === 'all-offices') {
      for (let key in allItems) {
        if (key.includes(`${collection}Items`)) {
          if (collection === 'scheduled') {
            let filteredNonScheduledItems = notScheduledItems.filter((item) => item.entryType === 'recall')
            callsHandledCount = scheduledItems.length + filteredNonScheduledItems.length + actionItems.length
          } else {
            if (key.includes('callsAnsweredItems')) {
              callsHandledCount = totalAnsweringServicesCallsHandled;
            }
          } 
          const items = allItems[key].filter(item => item.appointmentTime !== undefined);
          handledItems.push(...items)
        }
      }
      if(collection === 'treatment'){
        let filteredNonScheduledItems = notScheduledItems.filter((item) => item.entryType === 'treatment')
        callsHandledCount = scheduledTreatmentItems.length + filteredNonScheduledItems.length + actionItems.length
      }
    } else {
      let key;
      if (collection === 'scheduled') {
        key = 'scheduledItems'
      } else if (collection === 'callsAnswered') {
        key = 'callsAnsweredItems'
      }
      const appointmentsScheduledKey = currentOffice + key;
      if (collection === 'scheduled') {
        let filteredNonScheduledItems = notScheduledItems.filter((item) => item.entryType === 'recall')
        callsHandledCount = scheduledItems.length + filteredNonScheduledItems.length + actionItems.length
      } else if (collection === 'treatment') {
        let filteredNonScheduledItems = notScheduledItems.filter((item) => item.entryType === 'treatment')
        callsHandledCount = scheduledTreatmentItems.length + filteredNonScheduledItems.length + actionItems.length
      } else {
        callsHandledCount = allItems[appointmentsScheduledKey] && allItems[appointmentsScheduledKey].length;
      }
      const items = allItems[appointmentsScheduledKey] && allItems[appointmentsScheduledKey].filter(item => item.appointmentTime !== undefined);
      if (items) {
        handledItems.push(...items);
      }
    }

    if(isLoadingSummary) {
      return <Loader />;
    }

    count = collection === 'treatment' 
      ? scheduledTreatmentItems.length 
      : handledItems.length;
    return (
      <span className={classes.summary}>
        {this.renderSchStats(count)}
        {this.renderEstRev(count)}
        {/* {this.renderCallsHandled((showEntriesFor === 'recall' && !recallUpdated) ? recallsHandledCount + count : callsHandledCount)} */}
        {this.renderCallsHandled(callsHandledCount)}
      </span>
    );
  }

  render() {
    const {classes, isLoadingSummary, count, callsHandledCount, recordNotPresent, officeSubscription, params, showEntriesFor, allOfficesHaveSubscriptions} = this.props;
    const multipleOffices = params.office === 'all-offices';
    if (params.office !== 'all-offices' && officeSubscription && !officeSubscription[params.office][showEntriesFor]['show']) {
      return null;
    }
    
    if (recordNotPresent && !(count && callsHandledCount) && !(isLoadingSummary)) {
      return (
        <Typography variant='body2' className={classes.SummaryText}>No Records found for this date range.</Typography>
      )
    }
    
    if (multipleOffices && !allOfficesHaveSubscriptions){
      return null
    }
    return (
      <div>
        <Typography className={classes.summarySectionTitle} variant='h6'>
          SUMMARY
        </Typography>
        {this.renderMainBody()}
      </div>
    );
  }
}

const styles = theme => ({
  SummaryText: {
    marginTop: '30px',
    textAlign: 'center'
  },
  summarySectionTitle: {
    marginTop: '35px',
    textAlign: 'center',
    marginBottom: '15px',
  },
  summary: {
  	display: 'flex',
  	flexWrap: 'wrap',
    '& div': {
      flex: '1 0 100%',
      textAlign: 'center',
      boxSizing: 'border-box',
      color: '#171e42',
      padding: '10px',
      [theme.breakpoints.up('sm')]: {
        flex: '1 0 25%',
      },
    }
  },
  resultErr: {
    paddingTop: '15px',
  }
});

const mapStateToProps = (state, props) => {
  const { showEntriesFor, params, offices, officeSubscription } = props;
  const { getItems } = state;
  const scheduledItems = itemsCollection({
    getItems, 
    showEntriesFor, 
    params, 
    offices, 
    officeSubscription, 
    entry: 'scheduledEntry'
  });

  const scheduledTreatmentItems = itemsCollection({
    getItems, 
    showEntriesFor, 
    params, 
    offices, 
    officeSubscription, 
    entry: 'scheduledTreatmentEntry'
  });

  const notScheduledItems = itemsCollection({
    getItems, 
    showEntriesFor, 
    params, 
    offices, 
    officeSubscription, 
    entry: 'notScheduledEntry'
  });

  const actionItems = itemsCollection({
    getItems, 
    showEntriesFor, 
    params, 
    offices, 
    officeSubscription, 
    entry: 'actionEntry'
  });

  const allItems = state.getItems;
  const notScheduledRecallItems = [];
  let totalAnsweringServicesCallsHandled = 0;

  for (let key in allItems) {
      if (key.includes('notScheduledItems')) {
          const recalls = allItems[key].filter(recall => recall.entryType === 'recall');
          notScheduledRecallItems.push(...recalls);
      }

      if (key.includes('callsAnsweredItems')) {
        totalAnsweringServicesCallsHandled += allItems[key].length;
      }

  

  }

  return {
    officeDoc: state.getItems.officeDoc,
    count: state.getItems.count,
    callsHandledCount: state.getItems.callsHandledCount,
    isLoadingSummary: state.loadingData.summary,
    recordNotPresent: state.getItems.recordNotPresent,
    recallsHandledCount: notScheduledRecallItems.length,
    allItems,
    totalAnsweringServicesCallsHandled,
    scheduledItems: _.sortBy(
      _.filter(
        _.flatten(scheduledItems), 
        (o) => o.appointmentTime && !o.actionItem
      ),
      (o) => o.appointmentTime
    ),
    scheduledTreatmentItems: _.sortBy(
      _.filter(
        _.flatten(scheduledTreatmentItems), 
        (o) => o.appointmentTime && !o.actionItem
      ),
      (o) => o.appointmentTime
    ),
    notScheduledItems: _.filter(
      _.flatten(notScheduledItems), 
      (o) => !o.actionItem
    ),
    actionItems: _.flatten(actionItems),
  };
};

export default compose(connect(mapStateToProps), withStyles(styles, { withTheme: true }))(Summary);
