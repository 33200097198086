import _ from "lodash";
import React from "react";
import ArrowDown from "@material-ui/icons/ExpandMoreRounded";
import ArrowUp from "@material-ui/icons/ExpandLessRounded";
import Button from "@material-ui/core/Button";
import CallLogsDetails from "./common/CallLogsDetails";
import Collapse from "@material-ui/core/Collapse";
import itemsCollection from "./common/ItemsCollection";
import Typography from "@material-ui/core/Typography";
import { compose } from "redux";
import { connect } from "react-redux";
import { styles } from "./common/CommonStyles";
import { withStyles } from "@material-ui/core/styles";

const WebsiteChatItems = ({
  classes,
  websiteChatItems,
  offeringsCategories,
  allOffices,
  params,
  isLoading,
  currentUser,
  showEntriesFor,
  entryCollection
}) => {
  const [open, setOpen] = React.useState(true);
  if (_.isEmpty(websiteChatItems) && !isLoading) return null;

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <span>
      <Button
        className={classes.sectionCollapseButton}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Typography variant="subtitle2" color="secondary">
          WEBSITE CHATS ANSWERED ({websiteChatItems.length}){" "}
          {open ? (
            <ArrowDown viewBox="0 0 24 12" />
          ) : (
            <ArrowUp viewBox="0 0 24 12" />
          )}
        </Typography>
      </Button>
      <Collapse in={open} timeout="auto" unmountinexit="true">
        <div className={classes.itemEntry}>
          <CallLogsDetails
            classes={classes}
            items={websiteChatItems}
            offeringsCategories={offeringsCategories}
            allOffices={allOffices}
            params={params}
            isLoading={isLoading}
            user={currentUser}
            showEntriesFor={showEntriesFor}
            entryCollection={entryCollection}
          />
        </div>
      </Collapse>
    </span>
  );
};

const mapStateToProps = (state, props) => {
  const { params, offices, officeSubscription, showEntriesFor } = props;
  const { getItems } = state;
  const websiteChatItems = itemsCollection({
    getItems,
    showEntriesFor,
    params,
    offices,
    officeSubscription,
    entry: "websiteChatEntry",
  });

  return {
    offeringsCategories: _.mapKeys(state.offeringsCategories, "objectID"),
    websiteChatItems: _.filter(_.flatten(websiteChatItems), (o) => {
      return !o.appointmentTime && !o.actionItem;
    }),
    isLoading: state.loadingData.websiteChat,
    currentUser: state.firebase.auth.email,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(WebsiteChatItems);
