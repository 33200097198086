import _ from "lodash";

import {
  ITEMS_CALLS_ANSWERED,
  ITEMS_WEBSITE_CHAT,
  ITEMS_NOT_SCHEDULED,
  ITEMS_RESCHEDULED,
  ITEMS_SCHEDULED,
  ITEMS_SCHEDULED_TREATMENT,
  ACTION_ITEMS_SCHEDULED_TREATMENT,
  ACTION_ITEMS_CALLS_ANSWERED,
  ACTION_ITEMS_WEBSITE_CHAT,
  ACTION_ITEMS_SCHEDULED,
  ACTION_ITEMS_RESCHEDULED,
  ACTION_ITEMS_NOT_SCHEDULED,
} from "../../services/store/keys";

const ITEMS = {
  actionEntry: [
    "scheduled",
    "callsAnswered",
    "notScheduled",
    "reScheduled",
    "scheduledTreatment",
    "websiteChat",
  ],
  recallActionEntry: ["scheduled", "notScheduled", "reScheduled"],
  answeringActionEntry: ["callsAnswered"],
  scheduledTreatmentActionEntry: ["scheduledTreatment"],
  chatActionEntry: ["websiteChat"],
  callsAnsweredEntry: ["callsAnswered"],
  scheduledEntry: ["scheduled", "rescheduled"],
  notScheduledEntry: ["notScheduled"],
  scheduledTreatmentEntry: ["scheduledTreatment"],
  websiteChatEntry: ["websiteChat"],
};

const itemsCollection = ({
  getItems,
  showEntriesFor,
  params,
  offices,
  officeSubscription,
  entry,
}) => {
  let itemCollection = [];
  let itemsList = [];

  if (showEntriesFor === "recall" && entry === "actionEntry") {
    entry = "recallActionEntry";
  } else if (showEntriesFor === "answering" && entry === "actionEntry") {
    entry = "answeringActionEntry";
  } else if (showEntriesFor === "websiteChat" && entry === "actionEntry") {
    entry = "chatActionEntry";
  } else if (showEntriesFor === "treatment" && entry === "actionEntry") {
    entry = "scheduledTreatmentActionEntry";
  }

  if (params.office === "all-offices") {
    offices &&
      offices.forEach((item) => {
        if (
          !_.isNull(officeSubscription) &&
          officeSubscription[item] &&
          officeSubscription[item][showEntriesFor] &&
          officeSubscription[item][showEntriesFor].show
        ) {
          ITEMS[entry].forEach((singleEntry) => {
            if (
              (singleEntry === "scheduled" &&
                showEntriesFor !== "answering" &&
                showEntriesFor !== "websiteChat") ||
              (singleEntry === "scheduled" && entry === "actionEntry")
            ) {
              itemsList = getItems[`${item}${ITEMS_SCHEDULED}`];
              let itemsListReSch = getItems[`${item}${ITEMS_RESCHEDULED}`];
              if (
                entry === "actionEntry" ||
                entry === "answeringActionEntry" ||
                entry === "recallActionEntry"
              ) {
                itemsList = getItems[`${item}${ACTION_ITEMS_SCHEDULED}`];
                itemsListReSch = getItems[`${item}${ACTION_ITEMS_RESCHEDULED}`];
              }
              if (!_.isEmpty(itemsList)) {
                itemsList.forEach((item) => {
                  item["collection"] = singleEntry;
                });
                itemCollection.push(itemsList);
              }
              if (
                !_.isEmpty(itemsListReSch) &&
                ((entry === "actionEntry" && showEntriesFor !== "answering") ||
                  entry !== "actionEntry")
              ) {
                itemsListReSch.forEach((item) => {
                  item["collection"] = singleEntry;
                });
                itemCollection.push(itemsListReSch);
              }
            }

            if (
              singleEntry === "callsAnswered" ||
              (singleEntry === "scheduled" &&
                showEntriesFor !== "recall" &&
                showEntriesFor !== "websiteChat" &&
                entry !== "actionEntry")
            ) {
              itemsList = getItems[`${item}${ITEMS_CALLS_ANSWERED}`];
              if (
                entry === "actionEntry" ||
                entry === "answeringActionEntry" ||
                entry === "recallActionEntry" ||
                entry === "chatActionEntry"
              ) {
                itemsList = getItems[`${item}${ACTION_ITEMS_CALLS_ANSWERED}`];
              }
              if (
                !_.isEmpty(itemsList) &&
                (entry !== "actionEntry" ||
                  (entry === "actionEntry" && showEntriesFor !== "recall"))
              ) {
                itemsList.forEach((item) => {
                  item["collection"] = singleEntry;
                });
                itemCollection.push(itemsList);
              }
            }

            if (
              singleEntry === "websiteChat" ||
              (singleEntry === "scheduled" &&
                showEntriesFor !== "recall" &&
                showEntriesFor !== "answering" &&
                entry !== "actionEntry")
            ) {
              itemsList = getItems[`${item}${ITEMS_WEBSITE_CHAT}`];
              if (
                entry === "actionEntry" ||
                entry === "answeringActionEntry" ||
                entry === "recallActionEntry" ||
                entry === "chatActionEntry"
              ) {
                itemsList = getItems[`${item}${ACTION_ITEMS_WEBSITE_CHAT}`];
              }
              if (
                !_.isEmpty(itemsList) &&
                (entry !== "actionEntry" ||
                  (entry === "actionEntry" && showEntriesFor !== "recall"))
              ) {
                itemsList.forEach((item) => {
                  item["collection"] = singleEntry;
                });
                itemCollection.push(itemsList);
              }
            }

            if (
              singleEntry === "notScheduled" &&
              (entry !== "actionEntry" ||
                (entry === "actionEntry" && showEntriesFor !== "answering"))
            ) {
              itemsList = getItems[`${item}${ITEMS_NOT_SCHEDULED}`];
              if (itemsList && (showEntriesFor === "recall" || showEntriesFor === "treatment")) {
                itemsList = itemsList.filter(item => item.entryType === showEntriesFor);
              }

              if (
                entry === "actionEntry" ||
                entry === "answeringActionEntry" ||
                entry === "recallActionEntry"
              ) {
                itemsList = getItems[`${item}${ACTION_ITEMS_NOT_SCHEDULED}`];
                if (itemsList && (showEntriesFor === "recall" || showEntriesFor === "treatment")) {
                  itemsList = itemsList.filter(item => item.entryType === showEntriesFor);
                }
              }
              if (!_.isEmpty(itemsList)) {
                itemsList.forEach((item) => {
                  item["collection"] = singleEntry;
                });
                itemCollection.push(itemsList);
              }
            }

            if (
              singleEntry === "scheduledTreatment" &&
              (entry !== "actionEntry" ||
                (entry === "actionEntry" &&
                  showEntriesFor !== "scheduledTreatment"))
            ) {
              itemsList = getItems[`${item}${ITEMS_SCHEDULED_TREATMENT}`];
              if (
                entry === "actionEntry" ||
                entry === "answeringActionEntry" ||
                entry === "recallActionEntry" ||
                entry === "scheduledTreatmentActionEntry"
              ) {
                itemsList =
                  getItems[`${item}${ACTION_ITEMS_SCHEDULED_TREATMENT}`];
              }
              if (!_.isEmpty(itemsList)) {
                itemsList.forEach((item) => {
                  item["collection"] = singleEntry;
                });
                itemCollection.push(itemsList);
              }
            }
          });
        }
      });
  } else {
    if (
      !_.isNull(officeSubscription) &&
      officeSubscription[params.office] &&
      officeSubscription[params.office][showEntriesFor] &&
      officeSubscription[params.office][showEntriesFor].show
    ) {
      ITEMS[entry].forEach((singleEntry) => {
        if (
          (singleEntry === "scheduled" &&
            showEntriesFor !== "answering" &&
            showEntriesFor !== "websiteChat") ||
          (singleEntry === "scheduled" && entry === "actionEntry")
        ) {
          itemsList = getItems[`${params.office}${ITEMS_SCHEDULED}`];
          let itemsListReSch = getItems[`${params.office}${ITEMS_RESCHEDULED}`];
          if (
            entry === "actionEntry" ||
            entry === "answeringActionEntry" ||
            entry === "recallActionEntry"
          ) {
            itemsList = getItems[`${params.office}${ACTION_ITEMS_SCHEDULED}`];
            itemsListReSch =
              getItems[`${params.office}${ACTION_ITEMS_RESCHEDULED}`];
          }
          if (!_.isEmpty(itemsList)) {
            itemsList.forEach((item) => {
              item["collection"] = singleEntry;
            });
            itemCollection.push(itemsList);
          }
          if (
            !_.isEmpty(itemsListReSch) &&
            ((entry === "actionEntry" && showEntriesFor !== "answering") ||
              entry !== "actionEntry")
          ) {
            itemsListReSch.forEach((item) => {
              item["collection"] = singleEntry;
            });
            itemCollection.push(itemsListReSch);
          }
        }

        if (
          singleEntry === "callsAnswered" ||
          (singleEntry === "scheduled" &&
            showEntriesFor !== "recall" &&
            showEntriesFor !== "websiteChat" &&
            entry !== "actionEntry")
        ) {
          itemsList = getItems[`${params.office}${ITEMS_CALLS_ANSWERED}`];
          if (
            entry === "actionEntry" ||
            entry === "answeringActionEntry" ||
            entry === "recallActionEntry"
          ) {
            itemsList =
              getItems[`${params.office}${ACTION_ITEMS_CALLS_ANSWERED}`];
          }
          if (
            !_.isEmpty(itemsList) &&
            (entry !== "actionEntry" ||
              (entry === "actionEntry" && showEntriesFor !== "recall"))
          ) {
            itemsList.forEach((item) => {
              item["collection"] = singleEntry;
            });
            itemCollection.push(itemsList);
          }
        }

        if (
          singleEntry === "websiteChat" ||
          (singleEntry === "scheduled" &&
            showEntriesFor !== "recall" &&
            showEntriesFor !== "answering" &&
            entry !== "actionEntry")
        ) {
          itemsList = getItems[`${params.office}${ITEMS_WEBSITE_CHAT}`];
          if (
            entry === "actionEntry" ||
            entry === "answeringActionEntry" ||
            entry === "recallActionEntry" ||
            entry === "chatActionEntry"
          ) {
            itemsList =
              getItems[`${params.office}${ACTION_ITEMS_WEBSITE_CHAT}`];
          }
          if (
            !_.isEmpty(itemsList) &&
            (entry !== "actionEntry" ||
              (entry === "actionEntry" && showEntriesFor !== "recall"))
          ) {
            itemsList.forEach((item) => {
              item["collection"] = singleEntry;
            });
            itemCollection.push(itemsList);
          }
        }

        if (
          singleEntry === "notScheduled" &&
          (entry !== "actionEntry" ||
            (entry === "actionEntry" && showEntriesFor !== "answering"))
        ) {
          itemsList = getItems[`${params.office}${ITEMS_NOT_SCHEDULED}`];
          if (
            entry === "actionEntry" ||
            entry === "answeringActionEntry" ||
            entry === "recallActionEntry"
          ) {
            itemsList =
              getItems[`${params.office}${ACTION_ITEMS_NOT_SCHEDULED}`];
          }
          if (!_.isEmpty(itemsList)) {
            itemsList.forEach((item) => {
              item["collection"] = singleEntry;
            });
            itemCollection.push(itemsList);
          }
        }

        if (
          singleEntry === "scheduledTreatment" &&
          (entry !== "actionEntry" ||
            (entry === "actionEntry" &&
              showEntriesFor !== "scheduledTreatment"))
        ) {
          itemsList = getItems[`${params.office}${ITEMS_SCHEDULED_TREATMENT}`];
          if (
            entry === "actionEntry" ||
            entry === "answeringActionEntry" ||
            entry === "recallActionEntry" ||
            entry === "scheduledTreatmentActionEntry"
          ) {
            itemsList =
              getItems[`${params.office}${ACTION_ITEMS_SCHEDULED_TREATMENT}`];
          }
          if (!_.isEmpty(itemsList)) {
            itemsList.forEach((item) => {
              item["collection"] = singleEntry;
            });
            itemCollection.push(itemsList);
          }
        }
      });
    }
  }

  return itemCollection;
};

export default itemsCollection;
