import React, { Component } from 'react';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import Countdown from 'react-countdown-now';
import Loader from 'react-loader-spinner';
import logo from '../../services/images/logo.png';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { slideUpTransitionOptions } from '../../services/transitions';
import { styles } from './styles';
import { withFirebase } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

class ResetPassword extends Component {
  state = {
    errorText: '',
    successText: '',
    successWaitAuthText: '',
    successWaitRedirectText: '',
    formSuccess: false,
    formError: false,
    invalidCode: null,
    loadingAfterSubmit: false,
  }
  
  UNSAFE_componentWillMount() {
    const { firebase, oobCode } = this.props;
    
    firebase.auth().verifyPasswordResetCode(oobCode)
      .then(() => this.setState({ invalidCode: false }))
      .catch(() => this.setState({ invalidCode: true }));
  }

  onSubmit(values) {
    const { firebase, oobCode, reset } = this.props;
    const { password } = values;

    reset();
    this.setState({
      formSucces: false,
      formError: false,
      loadingAfterSubmit: true
    });
    
    firebase.auth().confirmPasswordReset(oobCode, password)
      .then(res => {
        this.resetPasswordSuccess(password);
      })
      .catch(err => {
        const requestAgainText = 'Please try requesting a password reset again';
        const tryAgainText = 'Please try again';
        switch (err.code) {
          case 'auth/expired-action-code':
            this.setState({
              errorText: `
                Your password reset code has expired. ${requestAgainText}.
              `
            });
            break;
          case 'auth/invalid-action-code':
            this.setState({ 
              errorText: `
                Your password reset code is invalid. ${requestAgainText}.
              `
            });
            break;
          case 'auth/user-disabled':
            this.setState({ 
              errorText: `
                The user for which you are attempting to reset the password has been disabled. 
                ${requestAgainText}.
              `
            });
            break;
          case 'auth/user-not-found':
            this.setState({ 
              errorText: `
                The user for which you are attempting to reset the password cannot be found. 
                ${requestAgainText}.
              `
            });
            break;
          case 'auth/weak-password':
            this.setState({ 
              errorText: `Your password is too weak. ${tryAgainText}.`
            });
            break;
          default:
            this.setState({ 
              errorText: `An error occured while resetting your password. ${requestAgainText}.` 
            });
        }
        this.setState({
          errorText: 'There was an error.',
          formError: true,
          formSuccess: false,
          loadingAfterSubmit: false
        });
      });
  }
  
  resetPasswordSuccess = (password) => {
    const { redirectUri } = this.props;
    
    if (redirectUri) {
      this.setState({
        successWaitAuthText: true,
        formSuccess: true,
        formError: false,
        loadingAfterSubmit: false
      }, () => {
        this.authenticateUser(password);
      });
    } else {
      this.setState({
        successWaitRedirectText: true,
        formSuccess: true,
        formError: false,
        loadingAfterSubmit: false
      });
    }
  }
  
  authenticateUser = (password) => {
    const { emailParam, firebase, history, redirectUri } = this.props;
    const decodedEmail = decodeURIComponent(emailParam);
    
    firebase.login({ email: decodedEmail, password })
      .then(res => {
        history.push(`${decodeURIComponent(redirectUri)}`);
      })
      .catch(err => {
        console.error(err);
        history.push(`/login?redirectUri=${redirectUri}`);
      });
  }
  
  renderControls = () => {
    const { classes, history } = this.props;
    
    if (this.state.invalidCode) {
      return (
        <div className={classes.controls}>
          <Button
            onClick={() => history.push('/login')}
            variant="outlined"
            color="secondary"
            className={classes.loginButton}>
            <BackIcon /> Login
          </Button>
        </div>
      );
    }
    
    return null;
  }
  
  renderText = () => {
    const { classes } = this.props;  

    if (this.state.formError) {
      return (
        <Typography variant="body1" className={classes.loginError}>
          {this.state.errorText}
        </Typography>
      );
    } else if (this.state.formSuccess) {
      if (this.state.successText) {
        return (
          <Typography variant="body1" style={{ textAlign: 'center' }}>
            {this.state.successText}
          </Typography>
        );    
      } else if (this.state.successWaitAuthText) {
        return (
          <span style={{ textAlign: 'center' }}>
          <Typography variant="body1">
            <b>Success!</b> 
            <br />
            Your password was successfully reset. Please wait while you are authenticated.
            <br />
          </Typography>
          <Loader 
            type="Oval" 
            color={this.props.theme.palette.primary.main} 
            height="20" 
            width="20" 
          />
          </span>
        );
      } else if (this.state.successWaitRedirectText) {
        return (
          <Typography variant="body1" style={{ textAlign: 'center' }}>
            <b>Success!</b> 
            <br />
            Your password was successfully reset. You will be redirected to login in 
            <Countdown 
              date={(Date.now() + 3000)} 
              renderer={({ seconds }) => {
                return <span>{` ${seconds}`}</span>;
              }}
              zeroPadLength={0}
              onComplete={() => this.props.history.push('/login')} 
            />...
          </Typography>
        );
      } else {
        return null;
      }
    } else {
      return (
        <Typography variant="body1">
          Please enter and confirm you new password below.
        </Typography>
      );
    }
  }
  
  renderButtonText = () => {
    if (this.state.loadingAfterSubmit) {
      return <Loader type="Oval" color="#ffffff" height="13" width="13" />;
    } else {
      return 'Save Password';
    }
  }

  renderTextField(field) {
    const { meta: { touched, error } } = field;

    return (
      <TextField
        label={field.label}
        className={field.className}
        margin="normal"
        type={field.type}
        autoComplete={field.fieldType === "password" ? "off" : field.autoComplete}
        error={touched && error ? true : false}
        helperText={touched && error}
        variant="outlined"
        {...field.input}
      />
    );
  }
  
  renderFormFields = () => {
    const { classes } = this.props;
  
    if (!this.state.formSuccess) {
      return (
        <span>
          <Field
            name="password"
            component={this.renderTextField}
            label="New Password"
            type="password"
            className={classes.textField}
            autoComplete="password"
          />
          <Field
            name="passwordConfirm"
            component={this.renderTextField}
            label="Confirm Password"
            type="password"
            className={classes.textField}
            autoComplete="password"
          />
          <div className={classes.buttonContainer}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              {this.renderButtonText()}
            </Button>
          </div>
        </span>
      );
    }
    
    return null;
  }
  
  renderForm = () => {
    const { classes, handleSubmit, theme } = this.props;
    
    if (this.state.invalidCode === null) {
      return (
        <div className={classes.initialLoading}>
          <Loader 
            type="Oval" 
            color={theme.palette.primary.main} 
            height="20" 
            width="20" 
          />
        </div>
      );
    } else if (this.state.invalidCode) {
      return (
        <Typography variant="body1" className={classes.loginError}>
          Your password reset code has either expired or is invalid. Please try requesting
          a password reset again.
        </Typography>
      ); 
    } else {
      return (
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(this.onSubmit.bind(this))}
        >
          {this.renderText()}
          {this.renderFormFields()}
        </form>
      );
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={`ResetPassword ${classes.root}`}>
        {this.renderControls()}
        <div className={classes.cardContainer}>
          <div className={classes.card}>
          <div className={classes.content}>
            <div className={classes.header}>
              <img src={logo} alt="Logo" className={classes.logo} />
            </div>
            <div>
              <ReactCSSTransitionGroup {...slideUpTransitionOptions}>
                {this.renderForm()}
              </ReactCSSTransitionGroup>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = 'Please enter your new password';
  }
  
  if (!values.passwordConfirm) {
    errors.passwordConfirm = 'Please confirm your new password';
  }

  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = 'Your passwords don\'t match.';
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  const params = ownProps.match.params;
  let redirectUri;
  
  if (
    params.redirectUri &&
    !params.redirectUri.includes('login') &&
    !params.redirectUri.includes('forgot-password') &&
    !params.redirectUri.includes('reset-password')
  ) {
    redirectUri = params.redirectUri;
  } else {
    redirectUri = null;
  }

  return {
    oobCode: params.oobCode,
    emailParam: params.emailParam,
    redirectUri,
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    validate,
    form: 'ResetPasswordForm',
    enableReinitialize : true
  }),
  withFirebase,
  withRouter,
  withStyles(styles, { withTheme: true })
)(ResetPassword);
