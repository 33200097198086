export const OFFICE = 'officeInfo';

export const ITEMS_CALLS_ANSWERED = 'callsAnsweredItems';
export const ITEMS_NOT_SCHEDULED = 'notScheduledItems';
export const ITEMS_SCHEDULED = 'scheduledItems';
export const ITEMS_RESCHEDULED = 'reScheduledItems';
export const ITEMS_WEBSITE_CHAT = 'websiteChatItems';
export const ITEMS_SCHEDULED_TREATMENT = 'scheduledTreatmentItems';

export const STATS_SCHEDULED = 'scheduledStats';
export const STATS_RESCHEDULED = 'rescheduledStats';
export const STATS_NOT_SCHEDULED = 'notScheduledStats';
export const STATS_CALLS_ANSWERED = 'callsAnsweredStats';
export const STATS_WEBSITE_CHAT = 'websiteChatStats';
export const STATS_SCHEDULED_TREATMENT = 'scheduledTreatmentStats';

export const ACTION_ITEMS_SCHEDULED = 'scheduledActionItems';
export const ACTION_ITEMS_RESCHEDULED = 'reScheduledActionItems';
export const ACTION_ITEMS_NOT_SCHEDULED = 'notScheduledActionItems';
export const ACTION_ITEMS_CALLS_ANSWERED = 'callsAnsweredActionItems';
export const ACTION_ITEMS_WEBSITE_CHAT = 'websiteChatActionItems';
export const ACTION_ITEMS_SCHEDULED_TREATMENT = 'scheduledTreatmentActionItems';

export const COLLECTIONS = [
  {
    name: 'scheduled',
    statsKey: STATS_SCHEDULED,
    itemsKey: ITEMS_SCHEDULED
  },
  {
    name: 'rescheduled',
    statsKey: STATS_RESCHEDULED,
    itemsKey: ITEMS_RESCHEDULED
  },
  {
    name: 'notScheduled',
    statsKey: STATS_NOT_SCHEDULED,
    itemsKey: ITEMS_SCHEDULED
  },
  {
    name: 'callsAnswered',
    statsKey: STATS_CALLS_ANSWERED,
    itemsKey: ITEMS_CALLS_ANSWERED
  },
  {
    name: 'websiteChat',
    statsKey: STATS_WEBSITE_CHAT,
    itemsKey: ITEMS_WEBSITE_CHAT
  },
  {
    name: 'scheduledTreatment',
    statsKey: STATS_SCHEDULED_TREATMENT,
    itemsKey: ITEMS_SCHEDULED_TREATMENT
  }
];

export const NEW_COLLECTIONS = {
  "scheduled": {
    statsKey: STATS_SCHEDULED,
    itemsKey: ITEMS_SCHEDULED,
    actionItemsKey: ACTION_ITEMS_SCHEDULED,
  },
  "reScheduled": {
    statsKey: STATS_RESCHEDULED,
    itemsKey: ITEMS_RESCHEDULED,
    actionItemsKey: ACTION_ITEMS_RESCHEDULED
  },
  "notScheduled": {
    statsKey: STATS_NOT_SCHEDULED,
    itemsKey: ITEMS_NOT_SCHEDULED,
    actionItemsKey: ACTION_ITEMS_NOT_SCHEDULED,
  },
  "callsAnswered": {
    statsKey: STATS_CALLS_ANSWERED,
    itemsKey: ITEMS_CALLS_ANSWERED,
    actionItemsKey: ACTION_ITEMS_CALLS_ANSWERED,
  },
  "websiteChat": {
    statsKey: STATS_WEBSITE_CHAT,
    itemsKey: ITEMS_WEBSITE_CHAT,
    actionItemsKey: ACTION_ITEMS_WEBSITE_CHAT,
  },
  "scheduledTreatment": {
    statsKey: STATS_SCHEDULED_TREATMENT,
    itemsKey: ITEMS_SCHEDULED_TREATMENT,
    actionItemsKey: ACTION_ITEMS_SCHEDULED_TREATMENT,
  }
};

export const ENTRIES =  {
  "all-services": ["scheduled", "callsAnswered", "notScheduled", "reScheduled", "websiteChat"],
  "recall": ["scheduled", "notScheduled"],
  "answering": ["scheduled", "callsAnswered"],
  "websiteChat": ["scheduled", "websiteChat"],
  "treatment": ["scheduledTreatment", "notScheduled"]
};

export const STATS_ENTRIES =  {
  "all-services": ["scheduled", "callsAnswered", "notScheduled", "reScheduled", "scheduledCallsAnswered", "websiteChat", "scheduledWebsiteChat"],
  "recall": ["scheduled", "notScheduled"],
  "answering": ["callsAnswered", "scheduledCallsAnswered"],
  "websiteChat": ["websiteChat", "scheduledWebsiteChat"],
  "treatment": ["scheduledTreatment"]
};

export const DOWNLOAD_ENTRIES = {
  "answering" : ["callsAnswered"],
  "recall" : ["notScheduled", "scheduled"],
  "all-services" : ["notScheduled", "scheduled", "callsAnswered", "websiteChat"],
  "websiteChat" : ["websiteChat"],
  "treatment": ["scheduledTreatment"]
}