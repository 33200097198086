import React, {useState} from 'react';
import { compose } from 'redux';
import { Checkbox, Switch, Grid, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles'

const CategoryFields = (props) => {
    const { subcategory, classes, detectNotificationsSection, setNewCategories, originalCategories, setCategoryChecked } = props;
    let { newCategories } = props;
    const [subCategoryCheked, setSubCategoryChecked] = useState(subcategory.id in originalCategories || Object.keys(originalCategories).length === 0);
    const [receiveSubTexts, setSubReceiveTexts] = useState(subcategory.id in originalCategories ? originalCategories[subcategory.id].text : false);
    const [receiveSubEmails, setSubReceiveEmails] = useState(subcategory.id in originalCategories ? originalCategories[subcategory.id].email : true);
    const resetSubCategory = () => {
        let tempCategories = {};
        delete newCategories[subcategory.id];
        let removeParentCategory = true;
        for(let category in newCategories){
            if(newCategories[category].parentCategory === subcategory.parentCategory){
                removeParentCategory = false;
            }
        }
        if(removeParentCategory){
            for(let category in newCategories){
                if(newCategories[category].category === subcategory.parentCategory){
                    delete newCategories[category];
                    setCategoryChecked(false);
                }
            }
        }
        tempCategories = newCategories;
        setNewCategories(tempCategories);
        setSubCategoryChecked(false);
        setSubReceiveTexts(false);
        setSubReceiveEmails(false);
    }

    const handleChecked = () => {
        if(!subCategoryCheked){
            newCategories[subcategory.id] = {
                category: subcategory.category, 
                parentCategory: subcategory.parentCategory, 
                text: false, 
                email: true
            };
            setSubReceiveTexts(false);
            setSubReceiveEmails(true);
            setSubCategoryChecked(true);
        }
        else{
            resetSubCategory();
        }
        
        detectNotificationsSection();
    }

    const handleTextChange = () => {
        newCategories[subcategory.id].text = !receiveSubTexts;
        if(!newCategories[subcategory.id].email && !newCategories[subcategory.id].text){
            resetSubCategory();
        }
        else{
            setSubReceiveTexts(!receiveSubTexts);
        }

        detectNotificationsSection();
    }

    const handleEmailChange = () => {
        newCategories[subcategory.id].email = !receiveSubEmails;
        if(!newCategories[subcategory.id].text && !newCategories[subcategory.id].email){
            resetSubCategory();
        }
        else{
            setSubReceiveEmails(!receiveSubEmails);
        }
        
        detectNotificationsSection();
    }

    return (
        <>
            <Grid item xs={12} className={classes.notificationSwitchs}>
                <FormControlLabel control={ <Checkbox onChange={handleChecked} checked={subCategoryCheked} color="secondary" /> } label={subcategory.category} />
                {
                    subCategoryCheked ? 
                    <>
                        <FormControlLabel control={ <Switch size="small" onChange={handleEmailChange} checked={receiveSubEmails} color="secondary" /> } label="Receive Emails" />
                        <FormControlLabel control={ <Switch size="small" onChange={handleTextChange} checked={receiveSubTexts} color="secondary" /> } label="Receive Texts" />
                    </>
                    : 
                    null
                }
            </Grid>
        </>
    )
}

export default compose(withStyles(styles, { withTheme: true }))(CategoryFields);