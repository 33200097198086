export const styles = theme => ({
  main: {
    display: 'flex',
    overflow: 'hidden',
    flexGrow: 1,
    width: '100%'
  },
  hamburger: {
    flex: '1',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    alignItems: 'center',
    justifyContent: 'center'
  },
  menubtn: {
    color: '#fff',
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    outline: 'none',
    display: 'flex',
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      borderRadius: '50%',
    },
    alignItems: 'center'
  },
  logoutbtn: {
    backgroundColor: '#696969',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    padding: '10px 20px',
    width: 'calc(100% - 240px)',
    display: 'flex',
    position: 'fixed',
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: '3',
    top: '0',
    marginLeft: '240px',
    height: '150px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0',
      height: '100px'
    },
    '&.full-width': {
      width: '100%',
      marginLeft: 0
    }
  },
  accountInfo: {
    height: 'calc(100% - 60px)',
  },
  root: {
    margin: '-60px auto 0',
    width: '100%',
    padding: '0px 10px 50px',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    }
  },
  controls: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    }
  },
  report: {
    marginTop: '200px',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(6),
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  header: {
    height: '60px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      height: '10px',
    },
  },
  logo: {
    display: 'block',
    width: '215px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '250px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sectionTitle: {
    marginTop: '45px',
    textAlign: 'center',
    marginBottom: '15px',
  },
  buttonOuterGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
      width: '100%',
      textAlign: 'center',
    }
  },
  button: {
    color: '#B2BABB',
    marginRight: '8px',
    boxShadow: 'none',
    borderRadius: '4px',
    '&:focus, &:hover': {
      backgroundColor: '#ec423d',
      color: '#fff',
    }
  },

  active: {
    backgroundColor: '#ec423d',
    color: '#fff',
  },
  wrapper: {
    margin: '64px 0 0',
    flexGrow: '1',
  }
});
