import React, { Component } from "react";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import Loader from "react-loader-spinner";
import logo from "../../services/images/logo.png";
import qs from "qs";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { remail } from "../../services/validation";
import { slideUpTransitionOptions } from "../../services/transitions";
import { styles } from "./styles";
import { withFirebase } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { functions } from "../../store";

class ForgotPassword extends Component {
  state = {
    errorText: "",
    successText: "",
    formSuccess: false,
    formError: false,
    loadingAfterSubmit: false
  };

  onSubmit = async values => {
    const { reset, redirectUri, setResetText } = this.props;
    const { email } = values;
    const host = `${window.location.protocol}//${window.location.hostname}`;
    const reqBody = { email, host };
    if (redirectUri) {
      reqBody.redirectUri = redirectUri;
    }

    this.setState({
      formSucces: false,
      formError: false,
      loadingAfterSubmit: true
    });

    try {
      await functions().httpsCallable("auth-resetPassword")(reqBody);
      reset();
      this.setState(
        {
          successText: (
            <span>
              <b>Success!</b>
              <br />
              {`An email with instructions on how to ${setResetText} your password was send to `}
              <br />
              <i>{email}</i>.
            </span>
          )
        },
        () => {
          this.setState({
            formSuccess: true,
            formError: false,
            loadingAfterSubmit: false
          });
        }
      );
    } catch (err) {
      this.setState({
        errorText: "There was an error; please try again.",
        formError: true,
        formSuccess: false,
        loadingAfterSubmit: false
      });
      // switch (err.response.data.code) {
      //   case 'auth/invalid-email':
      //     this.setState({
      //       errorText: 'Please enter a valid email address.'
      //     });
      //     break;
      //   case 'auth/user-not-found':
      //     this.setState({
      //       errorText: `
      //         The email you provided does not match any account in our system. Please try again.
      //       `
      //     });
      //     break;
      //   case 'auth/internal-error':
      //     const message = err.response.data.message;
      //     const strObj = message.split('An internal error has occurred. Raw server response: ')[1]
      //     const error = JSON.parse(strObj.slice(0, -1).substring(1)).error.message;

      //     switch (error) {
      //       case 'EMAIL_NOT_FOUND':
      //         this.setState({
      //           errorText: `
      //             The email you provided does not match any account in our system. Please try again.
      //           `
      //         });
      //         break;
      //       case 'RESET_PASSWORD_EXCEED_LIMIT':
      //         this.setState({
      //           errorText: `
      //             The reset password limit has been exceeded on your account. Please try again later.
      //           `
      //         });
      //         break;
      //       default:
      //         console.error(error);
      //         this.setState({ errorText: `An internal error occurred. Please try again.` });
      //     }
      //     break;
      //   default:
      //     this.setState({
      //       errorText: 'There was an error; please try again.'
      //     });
      // }

      // this.setState({
      //   formError: true,
      //   formSuccess: false,
      //   loadingAfterSubmit: false
      // });
    }
  };

  renderText = () => {
    const { classes, setResetText } = this.props;

    if (this.state.formError) {
      return (
        <Typography variant="body1" className={classes.loginError}>
          {this.state.errorText}
        </Typography>
      );
    } else if (this.state.formSuccess) {
      return (
        <Typography variant="body1" style={{ textAlign: "center" }}>
          {this.state.successText}
        </Typography>
      );
    } else {
      return (
        <Typography variant="body1">
          {`Please enter your email address to receive instructions on how to 
            ${setResetText} your password.`}
        </Typography>
      );
    }
  };

  renderButtonText = () => {
    if (this.state.loadingAfterSubmit) {
      return <Loader type="Oval" color="#ffffff" height="13" width="13" />;
    } else {
      return "Submit";
    }
  };

  renderControls = () => {
    const { classes, history } = this.props;

    return (
      <div className={classes.controls}>
        <Button
          onClick={() => history.push("/login")}
          variant="outlined"
          color="secondary"
          className={classes.logoutButton}
        >
          <BackIcon /> Login
        </Button>
      </div>
    );
  };

  renderTextField(field) {
    const {
      meta: { touched, error }
    } = field;

    return (
      <TextField
        label={field.label}
        className={field.className}
        margin="normal"
        type={field.type}
        autoComplete={
          field.fieldType === "password" ? "off" : field.autoComplete
        }
        error={touched && error ? true : false}
        helperText={touched && error}
        variant="outlined"
        {...field.input}
      />
    );
  }

  renderForm = () => {
    const { classes } = this.props;

    if (!this.state.formSuccess) {
      return (
        <span>
          <Field
            name="email"
            component={this.renderTextField}
            label="Email"
            type="email"
            className={classes.textField}
          />
          <div className={classes.buttonContainer}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              {this.renderButtonText()}
            </Button>
          </div>
        </span>
      );
    }

    return null;
  };

  render() {
    const { handleSubmit, classes } = this.props;

    return (
      <div className={`ForgotPassword ${classes.root}`}>
        {this.renderControls()}
        <div className={classes.cardContainer}>
          <div className={classes.card}>
            <div className={classes.content}>
              <div className={classes.header}>
                <img src={logo} alt="Logo" className={classes.logo} />
              </div>
              <div>
                <ReactCSSTransitionGroup {...slideUpTransitionOptions}>
                  <form
                    className={classes.form}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(this.onSubmit.bind(this))}
                  >
                    {this.renderText()}
                    {this.renderForm()}
                  </form>
                </ReactCSSTransitionGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = "Please enter your email address";
  }

  if (values.email) {
    if (!remail.test(values.email)) {
      errors.email = "Please enter a valid email address";
    }
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  let params = qs.parse(ownProps.location.search.slice(1));

  let redirectUri;

  if (
    params.redirectUri &&
    !params.redirectUri.includes("login") &&
    !params.redirectUri.includes("forgot-password") &&
    !params.redirectUri.includes("reset-password")
  ) {
    redirectUri = params.redirectUri;
  } else {
    redirectUri = null;
  }

  return {
    redirectUri,
    setResetText: params.hlip === "false" ? "set" : "reset"
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    validate,
    form: "ForgotPasswordForm",
    enableReinitialize: true
  }),
  withFirebase,
  withRouter,
  withStyles(styles, { withTheme: true })
)(ForgotPassword);
