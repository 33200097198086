import React, { useState } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import Typography from "@material-ui/core/Typography";
import UrgentIcon from "@material-ui/icons/Error";
import { parsePhoneNumber } from "libphonenumber-js";
import { Link } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { styles } from "./CommonStyles";
import { t } from "typy";
import { withStyles } from "@material-ui/core";
import ActionItemDialog from "../ActionItemDialog";
import queryString from "query-string";
import SingleEntry from "../../components/SingleEntry";

const RenderContentRight = ({ item, allOffices, offeringsCategories }) => {
  const generateReasonNotes = (item) => {
    return (
      <>
        { item.reason && item.reason.notes
            ? <span><b>Call Notes: </b>{item.reason.notes}<br/></span>
            : item.notes 
            ? <span><b>Call Notes: </b>{item.notes}<br/></span>
            : null
        }
        { item.reasonForCall 
            ? <span><b>Reason for Call: </b>{item.reasonForCall}<br/></span>
            : null 
        }
        
        { item.actionTaken 
            ? <span><b>Action(s) Taken </b>{item.actionTaken}<br/></span>
            : null 
        }
        { item.neededFollowUp 
            ? <span><b>Needed Follow-Up: </b>{item.neededFollowUp}<br/></span>
            : null 
        }
      </>
    );
  };

  const generateAppointment = () => {
    const office = _.filter(allOffices, (o) => o.id === item.office)[0];
    const officeTimezone = t(office, "officeInformation.timezone").safeObject
      ? t(office, "officeInformation.timezone").safeObject
      : moment.tz.guess(true);
    const appointmentTime = moment
      .unix(item.appointmentTime.seconds)
      .tz(officeTimezone);

    return (
      <>
        <span>
          <b>Date: </b>
          {appointmentTime.format("dddd, MMMM D, YYYY")}
          <br />
        </span>
        <span>
          <b>Time: </b>
          {appointmentTime.format("h:mm a")}
          <br />
        </span>
      </>
    );
  };

  const generateSubcategory = () => {
    if (offeringsCategories && item) {
      const subCategories =
        offeringsCategories[item.reason.category].subcategories;
      let subCategoryName = null;
      if (subCategories && item.reason.subcategory) {
        subCategoryName = subCategories.find(
          (subcategory) => subcategory.id === item.reason.subcategory
        ).category;
      }
      //display the notes of the call if the call happened before the new category format release
      if (
        (!subCategories || !item.reason.subcategory) &&
        item.reason &&
        item.reason.notes
      ) {
        subCategoryName = item.reason.notes;
      }
      return <span>{subCategoryName}</span>;
    }
  };

  if (item.actionItem) {
    return <Typography variant="body2">{generateReasonNotes(item)}</Typography>;
  } else if (item.appointmentTime) {
    return <Typography variant="body2">{generateAppointment()}</Typography>;
  } else {
    return <Typography variant="body2">{generateSubcategory()}</Typography>;
  }
};

const RenderContentMiddle = ({ offeringsCategories, item, allOffices }) => {
    if(offeringsCategories && item) {
      const category = item.reason 
        ? offeringsCategories[item.reason.category].category
        : 'Appointment Scheduled'
     
      const subCategory = item.reason && item.reason.subcategory 
        && offeringsCategories[item.reason.category].subcategories
        &&  offeringsCategories[item.reason.category].subcategories.find(
          subcategory => subcategory.id === item.reason.subcategory
        )
        ? offeringsCategories[item.reason.category].subcategories.find(
            subcategory => subcategory.id === item.reason.subcategory
          ).category
        : null
      const office = _.filter(allOffices, o => o.id === item.office)[0];
      const officeTimezone = t(office, 'officeInformation.timezone').safeObject
        ? t(office, 'officeInformation.timezone').safeObject
        : moment.tz.guess(true);
      const createdTime = moment.unix(item.createdTime.seconds).tz(officeTimezone);

      if(item.appointmentTime && item.reason && item.reason.subcategory === 'qWKs0gTgZZQV8er8Qmov') {
        return (
          <Typography variant="body2">
            <span>{category}</span><br/>
            <span style={{ fontSize: 12 }}>
              (call handled {
              createdTime.format('ddd, MMM D, YYYY')
              } at {
              createdTime.format('h:mm a')
              })
            </span>
          </Typography>
        )
      }
      if(!item.appointmentTime && !item.actionItem) {
        return (
          <Typography variant="body2">
            <span>{category}</span><br/>
            <span style={{ fontSize: 12 }}>
              (call handled {
              createdTime.format('ddd, MMM D, YYYY')
              } at {
              createdTime.format('h:mm a')
              })
            </span>
          </Typography>
        )
      }
      else {
        return (
          <Typography variant="body2">
              <span>{category} {subCategory ? ` - ${subCategory}` : null}</span><br/>
              <span style={{ fontSize: 12 }}>
              (call handled {
              createdTime.format('ddd, MMM D, YYYY')
              } at {
              createdTime.format('h:mm a')
              })
            </span>
          </Typography>
        )
      }
    }
  };


const CallLogDetails = ({
  setRefresh,
  singleItem,
  params,
  classes,
  allOffices,
  offeringsCategories,
  user,
  updateCompletedTotal,
  showEntriesFor,
  collection
}) => {
  const [openDialog, setopenDialog] = useState(false);
  const [completing, setcompleting] = useState(true);
  const [checked, setChecked] = useState(singleItem.completedBy);
  const locationPath = window.location.pathname;

  const parsed = queryString.parse(window.location.search);
  let entryInfo = null;

  if (parsed.entry) {
    entryInfo = JSON.parse(parsed.entry);
  }

  const createLink = () => {
    let newObject = {};
    newObject.id = singleItem.id;
    newObject.collection = collection ? collection : singleItem.collection;
    return newObject;
  };
  const handleCheck = (checked) => {
    setopenDialog(true);
    if (checked) {
      setcompleting(false);
    } else {
      setcompleting(true);
    }
  };

  let officeOfItem = null;
  if (params.office === "all-offices" && allOffices && allOffices.length) {
    officeOfItem = allOffices.filter(
      (office) => office.id === singleItem.office
    );
  }
  return (
    <>
      {allOffices.length > 0 ? (
        <SingleEntry
          openDialog={openDialog}
          setRefresh={setRefresh}
          params={params}
          singleItemInCompletedBy={singleItem.unCompletedBy}
          singleItemCompletedBy={singleItem.completedBy}
          setChecked={setChecked}
          updateCompletedTotal={updateCompletedTotal}
          Offices={allOffices}
          singleItemId={singleItem.id}
          entryInfo={entryInfo}
        />
      ) : null}
      <ActionItemDialog
        singleItem={singleItem}
        updateCompletedTotal={updateCompletedTotal}
        isOpen={openDialog}
        openDialog={setopenDialog}
        complete={completing}
        id={singleItem.id}
        collection={singleItem.collection}
        user={user}
        setChecked={setChecked}
        checked={checked}
        showEntriesFor={showEntriesFor}
      />
      <Link to={`${locationPath}?entry=${JSON.stringify(createLink())}`}>
        <span className={classes.contentContainer}>
          <div className={`${classes.cell} ${classes.contentLeft}`}>
            <Typography variant="body1">
              <b>{singleItem.patientName}</b>
              {singleItem.urgent ? (
                <span className={classes.urgentIcon}>
                  <UrgentIcon />
                </span>
              ) : (
                ""
              )}
              <br />
            </Typography>
            {singleItem && singleItem.urgent ? (
              <Typography variant="body2">
                <b style={{ color: "#ef3e36" }}>
                  URGENT
                  <br />
                </b>
              </Typography>
            ) : null}
            {params && params.office === "all-offices" ? (
              <Typography variant="body1" className={classes.officeName}>
                {!_.isNull(officeOfItem) &&
                officeOfItem.length &&
                officeOfItem[0].officeInformation
                  ? officeOfItem[0].officeInformation.name
                  : null}
                <br />
              </Typography>
            ) : null}
            <Typography variant="body1">
              <span className={classes.officeName}>
                {parsePhoneNumber(
                  singleItem.patientPhone,
                  "US"
                ).formatNational()}
                <br />
                {singleItem.actionItem ? (
                  <span className={classes.officeName}>
                    {checked ? (
                      <span className={classes.checkedActionIcon}>
                        <sub>
                          Completed By:
                          <br />
                          <span>{singleItem.completedBy} </span>
                        </sub>{" "}
                        <CheckBoxIcon
                          onClick={() => {
                            handleCheck(checked);
                          }}
                        />
                      </span>
                    ) : (
                      <span className={classes.actionIcon}>
                        <sub>
                          <span>Complete Item:</span>
                        </sub>{" "}
                        <CheckBoxOutlineBlankIcon
                          onClick={() => {
                            handleCheck(checked);
                          }}
                        />
                      </span>
                    )}
                  </span>
                ) : null}
                <br />
              </span>
            </Typography>
          </div>
          <div className={`${classes.cell} ${classes.contentMiddle}`}>
            {RenderContentMiddle({
              allOffices,
              classes,
              offeringsCategories,
              item: singleItem,
            })}
          </div>
          <div className={`${classes.cell} ${classes.contentRight}`}>
            {RenderContentRight({
              item: singleItem,
              allOffices,
              offeringsCategories,
            })}
          </div>
        </span>
      </Link>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(CallLogDetails);
