import React from 'react';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { DOWNLOAD_ENTRIES } from '../../services/store/keys';
import { generatePDF } from './GeneratePdf'

const PrintButton = (props) => {
    const {
      classes, 
      items, 
      office, 
      allOffices, 
      params, 
      offeringsCategories,
      officeDoc, 
      // allItems,
      // showEntriesFor,
      // actionItems,
      // scheduledItems,
      // notScheduledItems,
      // scheduledTreatmentItems
    } = props;
    let {
      count,
      callsHandledCount
    } = props;

    // let [updatedCountAndHandledCount, setUpdatedCountAndHandledCount] = useState(null)

    // useEffect(() => {
    //   console.log('firing?')
    //   if(count && callsHandledCount && allItems){
    //     console.log('firing')
    //     updateCountAndStatistics(count, callsHandledCount, allItems);
    //   }
    // }, [count, callsHandledCount])

    // const updateCountAndStatistics = (count, callsHandledCount) => {
    //   let currentOffice = params.office;
    //   let collection;
    //   if (showEntriesFor === 'answering') {
    //     collection = 'callsAnswered'
    //   } else if (showEntriesFor === 'treatment') {
    //     collection = 'treatment';
    //   } else {
    //     collection = 'scheduled';
    //   }
    //   const handledItems = [];
    //   console.log({showEntriesFor, currentOffice, collection, allItems}, 'props needed for proper print num')
    //   if (currentOffice === 'all-offices') {
    //     for (let key in allItems) {
    //       if (key.includes(`${collection}Items`)) {
    //         if (collection === 'scheduled') {
    //           callsHandledCount = scheduledItems.length +  notScheduledItems.length + actionItems.length
    //           console.log('recall', callsHandledCount, 'print')
    //         } else if (collection === 'treatment') {
    //           callsHandledCount = scheduledTreatmentItems.length +  notScheduledItems.length + actionItems.length
    //         } else {
    //           callsHandledCount = allItems[key].length;
    //         } 
    //         const items = collection = 'scheduled' ? allItems[key].filter(item => item.appointmentTime !== undefined) : allItems[key];
    //         console.log(items, 'recall', 'print')
    //         handledItems.push(...items)
    //       }
    //     }
    //   } else {
    //     let key;
    //     if (collection === 'scheduled') {
    //       key = 'scheduledItems'
    //     } else if (collection === 'callsAnswered') {
    //       key = 'callsAnsweredItems'
    //     }
    //     const appointmentsScheduledKey = currentOffice + key;
    //     if (collection === 'scheduled') {
    //       callsHandledCount = scheduledItems.length + notScheduledItems.length + actionItems.length
    //     } else if (collection === 'treatment') {
    //       callsHandledCount = scheduledTreatmentItems.length +  notScheduledItems.length + actionItems.length
    //     } else {
    //       callsHandledCount = allItems[appointmentsScheduledKey] && allItems[appointmentsScheduledKey].length;
    //     }
    //     const items = allItems[appointmentsScheduledKey] && allItems[appointmentsScheduledKey].filter(item => item.appointmentTime !== undefined);
    //     if (items) {
    //       handledItems.push(...items);
    //     }
    //   }

    //  count = collection === 'treatment' 
    //   ? scheduledTreatmentItems.length 
    //   : handledItems.length;

    //   setUpdatedCountAndHandledCount({count, callsHandledCount}) 
    // }
    
    const renderEstRev = (count) => {
            
        let apptValue = 0;
        let estShowRate = 0;
    
        officeDoc && officeDoc.forEach((doc) => {
          if(doc) {
            const { officeNotes } = doc;
            apptValue += officeNotes && officeNotes.apptValue ? officeNotes.apptValue : 0;
            estShowRate += officeNotes && officeNotes.estShowRate ? officeNotes.estShowRate : 0;
          }
        });
    
        apptValue = apptValue || 199;
        estShowRate = estShowRate || 0.75;
        return count * apptValue * estShowRate;
    }
   
    const printReport = () =>{
        generatePDF(items, office, allOffices, params, offeringsCategories, count, callsHandledCount, renderEstRev(count))
    }

    return(
        <div className={classes.root}>
            <IconButton  color="inherit" className={classes.dateRangeButton} onClick={printReport}>
                <PrintIcon className={classes.icon}/>
            </IconButton>
        </div>
    )
}


const styles = theme => ({
    root: {
      display: 'flex',
      paddingTop: 10,
      paddingRight: 5,
      marginTop: 5
    },
    icon: {
        '&:hover':{
            color: theme.palette.secondary.main
        }
    }
  });
  const mapStateToProps = (state, props) => {
    const { showEntriesFor, offices, params } = props;
    const officeSubscription = state.officeSubscription.officeSubscriptionDetails;
    const reportItems = [];
    let items = [];
    if(params.office === "all-offices") {
      offices && offices.forEach((item) => {
        if(!_.isNull(officeSubscription) && officeSubscription[item] && officeSubscription[item][showEntriesFor] && officeSubscription[item][showEntriesFor].show) {
          DOWNLOAD_ENTRIES[showEntriesFor].forEach((collection) => {
            items = state.getItems[`${item}${collection}Items`];
            if (!_.isEmpty(items)) {
              reportItems.push(items);
            }
          });
        }
      });
    } else {
      if(!_.isNull(officeSubscription) && officeSubscription[params.office] && officeSubscription[params.office][showEntriesFor] && officeSubscription[params.office][showEntriesFor].show) {
        DOWNLOAD_ENTRIES[showEntriesFor].forEach((collection) => {
          items = state.getItems[`${params.office}${collection}Items`];
          if (!_.isEmpty(items)) {
            reportItems.push(items);
          }
        });
      }
    }


    return {
        offeringsCategories: _.mapKeys(state.offeringsCategories, 'objectID'),
        items: _.flatten(reportItems),
        office: params.office,
        officeDoc: state.getItems.officeDoc,
        count: state.getItems.count,
        callsHandledCount: state.getItems.callsHandledCount
    };
  };
  
  export default compose(
    connect(mapStateToProps),
    withStyles(styles, { withTheme: true })
  )(PrintButton);